import {AfterViewInit, ChangeDetectorRef, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {GridApi} from "ag-grid-community";
import {DetectMethodChanges, DxValueChanged, isValidNumber, isVoid} from "../../../utils";
import {ToastrService} from "ngx-toastr";
import {OptionExpirationDescriptor} from "../../../shell-communication/shell-dto-protocol";
import {OptionsChainService} from "../../../option-chains.service";
import {HgHedgeMatrixComponent} from "../hg-hedge-matrix.component";
import {ApgDataService} from "../../../adjustment-pricing-grid/services/apg-data.service";

@Component({
    selector: 'ets-hg-matrix-punch-pad',
    templateUrl: './hg-matrix-punch-pad.component.html',
    styleUrls: ['./hg-matrix-punch-pad.component.scss'],
})
export class HgMatrixPunchPadComponent implements ICellEditorAngularComp, AfterViewInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService,
        private readonly _optionsChainService: OptionsChainService,
        private readonly _apgDataService: ApgDataService
    ) {
    }

    private _params: { api?: GridApi, etsComponent?: HgHedgeMatrixComponent } = {};

    private _originalQty: number;

    selectedExpiration: OptionExpirationDescriptor;

    expirationList: OptionExpirationDescriptor[] = [];

    side: 'Long' | 'Short';

    qty: number;

    // don't use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

    }

    agInit(params: any): void {
        this._originalQty = undefined;
        this.qty = undefined;
        this.selectedExpiration = undefined;
        this.expirationList = [];
        this.side = undefined;

        if (isValidNumber(params.value)) {
            this._originalQty = params.value;
            if (params.value < 0) {
                this.side = 'Short';
            } else if (params.value > 0) {
                this.side = 'Long';
            }
        }
        this.qty = this._originalQty;
        this._params = params;


        this._apgDataService.getUnderlyingOfPortfolio(
            this._params.etsComponent.selectedPortfolio
        ).then((data) => {
            return this._optionsChainService.getChain(data);
        }).then(data => {
            this.expirationList = data.expirations;
            this.selectedExpiration = this._params.etsComponent.activeExpiration;
            this._changeDetector.detectChanges();
        });
    }

    getValue(): any {
        return this.qty;
    }

    isPopup(): boolean {
        return true;
    }

    onQtySelected(value: number) {
        if (isVoid(this.side)) {
            this._toastr.error('Trade Side Not Selected');
            return;
        }

        if (this.side === 'Short') {
            value *= -1;
        }

        this.qty = value;

        this._params.api.stopEditing();
    }

    getPopupPosition() {
        return 'under';
    }

    @DetectMethodChanges()
    setSide(side: 'Long' | 'Short') {
        this.side = side;
        if (side === 'Short') {
            this.qty = Math.abs(this.qty) * -1;
        } else if (side === 'Long') {
            this.qty = Math.abs(this.qty);
        }
    }

    @DetectMethodChanges()
    onMinusClicked(num: number) {
        const current = isValidNumber(this.qty) ? this.qty : 0;
        const desired = current - num;
        this.qty = desired;
        this.updateSide();
    }

    @DetectMethodChanges()
    onPlusClicked(num: number) {
        const current = isValidNumber(this.qty) ? this.qty : 0;
        const desired = current + num;
        this.qty = desired;
        this.updateSide();
    }

    onApplyClicked() {
        this._params.api.stopEditing();
    }

    onCancelClicked() {
        this.qty = this._originalQty;
        this._params.api.stopEditing();
    }

    @DetectMethodChanges()
    reversePosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = this.qty * -1;
        this.updateSide();
    }

    @DetectMethodChanges()
    closePosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = undefined;
        this.updateSide();
    }

    @DetectMethodChanges()
    doublePosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = this.qty * 2;
        this.updateSide();
    }

    @DetectMethodChanges()
    halfPosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = Math.floor(this.qty / 2);
        this.updateSide();
    }

    @DetectMethodChanges()
    updateSide() {
        if (this.qty > 0) {
            this.side = 'Long';
        } else if (this.qty < 0) {
            this.side = 'Short';
        } else {
            this.side = undefined;
        }
    }

    onQtyChanged(args: DxValueChanged<number>) {
        if (isVoid(args.event)) {
            return;
        }
        this.updateSide();
    }
}