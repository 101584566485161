<dx-popup (onHidden)="this.onHidden()"
          [(visible)]="this.visible"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="525"
          [resizeEnabled]="true"
          [width]="880"
          title="Harvest Dialog"
>
  <div #wrapper *dxTemplate="let data of 'content'" class="wrapper">
    <div class="content">
      
      <div class="orders side">
        <fieldset class="order-group">
          <legend [style.color]="this.spreadColor" class="legend">{{ this.spreadName }}</legend>
          <div class="orders-list">
            <ng-container *ngFor="let order of this.theSpread">
              <ng-container *ngTemplateOutlet="orderTpl; context: {order: order}">
              </ng-container>
            </ng-container>
          </div>
        </fieldset>
        
        <hr class="separator">
        
        <div class="parameters">
          <div class="param amount">
            <div class="label">Amount to Harvest</div>
            <div class="input">
              <dx-number-box
                (onValueChanged)="this.onAmountToHarvestChanged($event)"
                [(value)]="this.amountToHarvest"
                [inputAttr]="{style: 'text-align: center;'}" [min]="0"
                format="$#0"
              >
              </dx-number-box>
            </div>
          </div>
          <div class="param step">
            <div class="label">{{ this.stepAction }} Step</div>
            <div class="input">
              <dx-number-box
                (onValueChanged)="this.onHarvestStepChanged($event)"
                [(value)]="this.harvestStep"
                [inputAttr]="{style: 'text-align: center;'}"
                [min]="0"
                format="$#0"
              ></dx-number-box>
            </div>
          </div>
          <div class="param qty">
            <div class="label">Qty Of Orders</div>
            <div class="input">
              <dx-number-box [(value)]="this.qtyOfOrders" [inputAttr]="{style: 'text-align: center;'}"
                             [readOnly]="true">
              </dx-number-box>
            </div>
          </div>
          <div class="param">
            <dx-button
              (onClick)="this.onApplyClicked()"
              [disabled]="!this.canApply"
              text="Apply" width="100%">
            </dx-button>
          </div>
        </div>
        
        <div class="box-mode">
          <dx-check-box text="Box Orders" [(value)]="this.boxMode" (onValueChanged)="this.onBoxModeChanged($event)">
          </dx-check-box>
        </div>
        
        <div class="limit-price">
          <div class="label">Limit Price</div>
          <div class="input">
            <dx-number-box format="$#0.00" [(value)]="this.limitPrice" [showClearButton]="true"
                           [inputAttr]="{style: 'text-align: center;'}">
            </dx-number-box>
          </div>
        </div>
      
      </div>
      
      <div class="drop-zones side">
        
        <div class="zones-list">
          <dx-scroll-view>
            <fieldset *ngFor="let order of this.chainedOrders" class="zone">
              <legend class="legend">{{ order.title }}</legend>
              
              <div class="zone-items">
                <div *ngFor="let leg of order.legs">
                  <ng-container *ngTemplateOutlet="orderTpl; context: {order: leg }">
                  </ng-container>
                </div>
              </div>
            
            </fieldset>
          </dx-scroll-view>
        </div>
        
        <hr class="separator">
        
        <div class="buttons">
          <div class="copy btn">
            <dx-button (onClick)="this.copyAllOrders()"
                       [disabled]="!this.canCopyOrders" text="Copy All Orders" width="100%"
            ></dx-button>
          </div>
        </div>
      
      </div>
    
    </div>
    
    <dx-load-panel [position]="{my: 'center', at:'center', of: wrapper}" [visible]="this.isLoading"></dx-load-panel>
  </div>

</dx-popup>

<ng-template #orderTpl let-order="order">
  <div class="draggable">
    
    <div [ngClass]="this.getClassForAction(order.action)" class="action col">
      {{ order.action }}
    </div>
    
    <div class="qty col">
      {{ order.qty }}
    </div>

    <div class="qty col">
      {{ order.underlying }}
    </div>
    
    <div class="expiration col">
      {{ order.expiration  | etsFriendlyDatePipe:'dte' }}
    </div>
    
    <div class="type col">
      {{ order.type }}
    </div>
    
    
    <div class="strike col">
      {{ order.strike }}
    </div>
  
  </div>

</ng-template>