import {OptionExpirationDescriptor} from "../../../shell-communication/shell-dto-protocol";
import {makeOptionTicker, OptionStrategy, OptionType, parseOptionTicker} from "../../../options-common/options.model";
import {OptionsChainService} from "../../../option-chains.service";
import {isNullOrUndefined, isOptionExpired, isValidNumber, isVoid} from "../../../utils";
import {HedgePosition} from "../../data-model/hedge-position";

export class HedgePositionModel {

    constructor() {
    }

    private _currentTicker: string;

    underlying: string;
    qty: number;
    transQty: number;
    strike: number;
    type: 'Call' | 'Put';
    expirationDescriptor: OptionExpirationDescriptor;
    expirationList: OptionExpirationDescriptor[];
    label?: string;
    groupId?: string;
    groupOrder?: number;
    price: number;
    color: string;
    strategy?: OptionStrategy;

    removed = false;
    selectedForMerge = false;
    selectedForTrans = false;
    selectedForHarvest = false;

    expiredTicker: string;
    expirationPnl: number;

    get expiration(): string {
        return this.expirationDescriptor?.optionExpirationDate;
    }

    get absQty(): number {
        return Math.abs(this.qty);
    }

    side: 'Buy' | 'Sell';

    get sideStyle(): string {
        if (isVoid(this.side)) {
            return undefined;
        }

        return this.side === 'Buy' ? 'text-align: center; color: cornflowerblue;' : 'text-align: center; color: red';
    }

    get expirationStyle(): any {
        if (isVoid(this.expirationDescriptor)) {
            return undefined;
        }

        let style = 'text-align: center;';
        if (this.expirationDescriptor.expirationTicker === '@SPX') {
            style += 'color: yellow;';
        }

        return {style};
    }

    asDto(): HedgePosition {

        const type = OptionType[this.type];

        const optionTicker = makeOptionTicker(
            this.expirationDescriptor,
            type,
            this.strike,
        );

        let qty = this.qty;
        if (isValidNumber(this.transQty)) {
            qty = Math.abs(this.transQty);
            if (this.side === 'Buy') {
                qty  = Math.abs(qty);
            } else if (this.side === 'Sell') {
                qty = Math.abs(qty) * -1;
            }
        }

        return {
            ticker: optionTicker,
            qty: qty,
            label: this.label,
            groupId: this.groupId,
            type: this.type,
            strike: this.strike,
            expiration: this.expiration,
            asset: this.underlying,
            color: this.color,
            groupOrder: this.groupOrder
        };
    }

    asComparisonUnit(): string {
        const dto = this.asDto();
        const state = `${dto.ticker}|${dto.qty}|${this.transQty || 0}|${dto.label}|${dto.color}|${this.removed}`;
        return state;
    }

    isValid(): boolean {
        const qty = isValidNumber(this.qty, true);

        if (!qty) {
            return false;
        }

        if (!isNullOrUndefined(this.transQty)) {
            if (!isValidNumber(this.transQty, true)) {
                return false;
            }
        }

        if (Math.abs(this.qty) === Math.abs(this.transQty)) {
            return false;
        }

        const side = ['Buy', 'Sell'].indexOf(this.side) !== -1;

        if (!side) {
            return false;
        }

        const strike = isValidNumber(this.strike, true);

        if (!strike) {
            return strike;
        }

        const groupId = !isVoid(this.groupId);

        if (!groupId) {
            return false;
        }

        const label = !isVoid(this.label);
        if (!label) {
            return false;
        }

        const color = !isVoid(this.color);

        if (!color){
            return false;
        }

        const type = !isVoid(this.type);

        if (!type) {
            return false;
        }

        const expiration = !isVoid(this.expiration);

        if (!expiration) {
            return false;
        }

        const asset = !isVoid(this.underlying);

        if (!asset) {
            return false;
        }

        return true;
    }

    getTicker(): string {
        if (isVoid(this._currentTicker)) {
            this._currentTicker = makeOptionTicker(
                this.expirationDescriptor,
                this.type,
                this.strike,
            );
        }

        return this._currentTicker;
    }

    getIdentity(): string {
        const ticker = this.getTicker();
        const res = `${ticker}|${this.qty}|${this.groupId}`;
        return res;
    }

    updateTicker() {
        this._currentTicker = makeOptionTicker(
            this.expirationDescriptor,
            this.type,
            this.strike,
        );
        this.price = undefined;
    }

    static async fromDto(dto: HedgePosition, svc: OptionsChainService): Promise<HedgePositionModel> {
        const optionTicker = parseOptionTicker(dto.ticker);
        const chain = await svc.getChain(optionTicker.underlying);
        const expiration = chain.expirations
            .find(x => x.optionExpirationDate === optionTicker.expiration);

        const model = new HedgePositionModel();
        model.underlying = optionTicker.underlying;
        model.qty = dto.qty;
        model.side = dto.qty > 0 ? 'Buy' : 'Sell';
        model.strike = optionTicker.strike;
        model.type = optionTicker.type;
        model.expirationDescriptor = expiration;
        model.expirationList = chain.expirations.slice();
        model.label = dto.label;
        model.groupId = dto.groupId;
        model.color = dto.color;
        model.groupOrder = dto.groupOrder;

        if (isOptionExpired(dto.ticker)) {
            model.expiredTicker = dto.ticker;
        }

        return model;
    }

    resetTransState() {
        this.transQty = undefined;
        this.removed = false;
        this.selectedForTrans = false;
        this.selectedForMerge = false;
    }

    clone(): HedgePositionModel {
        const model = new HedgePositionModel();
        model.underlying = this.underlying;
        model.qty = this.qty;
        model.side = this.side;
        model.strike = this.strike;
        model.type = this.type;
        model.expirationDescriptor = this.expirationDescriptor;
        model.expirationList = this.expirationList;
        model.label = this.label;
        model.groupId = this.groupId;
        model.color = this.color;
        model.groupOrder = this.groupOrder;

        return model;
    }
}