import {Injectable} from '@angular/core';
import {isValidNumber, isVoid} from "../utils";
import {ShellClientService} from "../shell-communication/shell-client.service";
import {
    GetMarketDataSnapshotShell,
    GetMarketDataSnapshotShellReply
} from "../shell-communication/shell-operations-protocol";

@Injectable()
export class SettlementPriceService {

    constructor(
        private _shellClient: ShellClientService
    ) {
    }

    private _promises: Record<string, Record<string, Promise<number>>> = {};

    reset() {
        this._promises = {};
    }

    getSettlementPriceWithRequest(ticker: string, expiration: string): Promise<number> {

        let tickerContainer = this._promises[ticker];

        if (isVoid(tickerContainer)) {
           tickerContainer = {};
           this._promises[ticker] = tickerContainer;
        }

        const settlementPricePromise = tickerContainer[expiration];

        if (!isVoid(settlementPricePromise)) {
            return settlementPricePromise;
        }

        const qry = new GetMarketDataSnapshotShell(
            ticker, expiration
        );

        const promise = this._shellClient.processQuery<GetMarketDataSnapshotShellReply>(qry)
            .then(result =>  result?.price);

        tickerContainer[expiration] = promise;

        return promise;
    }
}