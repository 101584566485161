import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import {ShellClientService} from '../shell-communication/shell-client.service';
import {
    AdjustmentPricingSettingsDto,
    GetAdjustmentSolutions,
    SetImpliedVolatilityShell,
    UpdateTrackingObjectOnSweetPriceSettingsUpdate,
    UpdateTrackingObjectsOnTemplateUpdate
} from '../shell-communication/shell-operations-protocol';
import {
    arraysEqual,
    balanceStatePositions,
    delay,
    DetectMethodChanges,
    DetectSetterChanges,
    DxValueChanged,
    findHCF,
    getAvailableCashFlowStrategies,
    getShortUUID,
    isNullOrUndefined,
    isReversedCashFlowOrder,
    isValidNumber,
    isVoid,
    makeFullExpirationDate,
    makeGuiFriendlyExpirationDate,
    removeFromArray
} from '../utils';
import {StrategiesSectionModel} from './model/StrategiesSectionModel';
import {GetAdjustmentSolutionsReply} from './model/GetAdjustmentSolutionsReply';
import {SettingsSectionModel} from './model/SettingsSectionModel';
import {LastQuoteCacheService} from '../last-quote-cache.service';
import {
    ApgPortfolioStateChangedDto,
    CashFlowStrategyRole,
    StrategyPriceDto,
    SweetPriceAvailableDto,
    TrackingNotificationSettingsUpdated,
    TrackingRequestErrorMessage,
    TrackingStartedMessage,
    TrackingStoppedMessage,
    TrackingTemplateUpdated
} from '../shell-communication/shell-dto-protocol';
import {MessageBusService} from '../message-bus.service';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {PriceboxSectionModel} from './model/PriceboxSectionModel';
import {ToastrService} from 'ngx-toastr';
import {OptionsChainService} from '../option-chains.service';
import {AtmStrikeService} from '../common-services/atm-strike-service/atm-strike.service';
import {CashFlowStrategy} from '../adjustment-control-panel/cash-flow-strategy';
import {TradingInstrument} from '../trading-instruments/trading-instrument.class';
import {DxScrollViewComponent} from 'devextreme-angular/ui/scroll-view';
import {QuoteDto} from '../shell-communication/dtos/quote-dto.class';
import {CashFlowStrategyTemplatesService} from './services/cashflow-strategy-templates.service';
import {SettingsStorageService} from '../settings-storage-service.service';
import {AccessControlService} from '../access-control-service.class';
import {TradingInstrumentsService} from '../trading-instruments/trading-instruments-service.interface';
import {ICashFlowAdjustmentSettingsTemplate} from './model/ICashFlowAdjustmentSettingsTemplate';
import {BeforePositionDto} from './model/BeforePositionDto';
import {PositionsRestoredEventArgs, PositionsSectionModel} from './positions-section/model/PositionsSectionModel';
import {LocationService} from '../location.service';
import {SessionService} from '../authentication/session-service.service';
import {CopyOrdersToService} from './services/copy-orders-to.service';
import {ApplicationSettingsService} from '../app-settings/application-settings.service';
import {PositionsStateService} from './services/positions-before-state.service';
import {SavedPositionsService} from './services/saved-positions.service';
import {ServiceConfiguration} from './services/ServiceConfiguration';
import {AssignableAdjustmentTemplatesService} from './settings-section/assignable-adjustment-templates.service';
import {ApgPortfolio, DefaultApgPortfolioId, getDefaultPortflio} from './model/ApgPortfolio';
import {CashFlowPortfoliosService} from './services/cashflow-portfolios.service';
import {PositionsAfterStateService} from './services/positions-after-state.service';
import {AdjustmentSolutionPopupComponent} from './solution-popup/adjustment-solution-popup.component';
import {StrategyCodeIndex} from './model/StrategyCodeIndex';
import {QuoteIndex} from './model/QuoteIndex';
import {CashFlowAdjustment} from './model/CashFlowAdjustment';
import {UserDto} from '../authentication/dtos/auth-result-dto.inteface';
import {ApgPortfolioRefreshDialogComponent} from './portfolio-refresh-dialog/apg-portfolio-refresh-dialog.component';
import {SweetPriceTrackingService} from './services/sweet-price-tracking.service';
import {SweetPriceSettings} from './positions-section/tracking-dialog/SweetPriceTrackingSettings';
import {SweetPricePopupConfig} from './positions-section/tracking-dialog/apg-tracking-dialog.component';
import {
    ApgTrackingSyncDialogComponent
} from './positions-section/tracking-sync-dialog/apg-tracking-sync-dialog.component';
import {GenericConfirmationDialogComponent} from '../generic-confirmation-dialog/generic-confirmation-dialog.component';
import {PriceboxPinnedRowsService} from './services/pricebox-pinned-rows.service';
import {ClipboardService} from "../clipboard.service";
import {PositionsData} from "./positions-section/model/PositionsData";
import {PriceboxMenuComponent} from "./pricebox-menu/pricebox-menu.component";
import {SolutionPositionDto} from "./model/SolutionPositionDto";
import {VisibleOffsetsSettingsService} from "./visible-offsets/visible-offsets-settings.service";
import {BeforePositionModel} from "./positions-section/model/BeforePositionModel";
import {SolutionOrderLegDto} from "./model/SolutionOrderLegDto";
import {SolutionOrderLegActions} from "./model/SolutionOrderLegActions";
import {DefaultSettingsMemento, DefaultsMemento} from "./positions-section/model/BucketBeforeStateDefaultsPopupModel";
import {DateTime} from "luxon";
import {makeOptionTicker} from "../options-common/options.model";
import {AfterStateDetalizationComponent} from "./after-state-detalization/after-state-detalization.component";
import {PriceboxRow} from "./model/PriceboxRow";
import {ZonesGridSettings, ZonesGridSettingsComponent} from "./zones-grid-settings/zones-grid-settings.component";
import {ZonesGridComponent} from "./zones-grid/zones-grid.component";
import {AtmStrikeChangedEvent} from "../common-services/atm-strike-service/atm-strike-changed-event";
import {ZonesGridMenuComponent} from "./zones-grid-menu/zones-grid-menu.component";
import {AfterStateDetalizationService} from "./after-state-detalization.service";
import {ApgHedgesTotalComponent} from "./hedges-total/apg-hedges-total.component";
import {ApgOriginalClientPositionsService} from "./services/apg-original-client-positions.service";
import * as Enumerable from "linq";
import {UserSettingsService} from "../user-settings.service";
import {HgSettingsSectionComponent} from "../hedging-grid/settings-section/hg-settings-section.component";
import {OverrideAtmDialogComponent} from "../override-atm-dialog/override-atm-dialog.component";
import {HedgePositionsService} from "../hedging-grid/positions-section/hedge-positions/hedge-positions.service";

export type ApgGridColumn = 'positions' | 'settings' | 'strategies' | 'pricebox' | 'zones-grid';

type DesiredMementoState = { expiration: string, strike: number, qty: number };

@Component({
    selector: 'ets-adjustment-pricing-grid',
    templateUrl: 'adjustment-pricing-grid.component.html',
    styleUrls: [
        './adjustment-pricing-grid.component.scss',
        './adjustment-grid-common-style.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        PositionsStateService,
        SavedPositionsService,
        CopyOrdersToService,
        CashFlowStrategyTemplatesService,
        CashFlowPortfoliosService,
        PositionsAfterStateService,
        PriceboxPinnedRowsService
    ]
})
export class AdjustmentPricingGridComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _shellClient: ShellClientService,
        private readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _atmStrikeService: AtmStrikeService,
        private readonly _optionChainsService: OptionsChainService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _positionsStateService: PositionsStateService,
        private readonly _cashFlowStrategyTemplatesService: CashFlowStrategyTemplatesService,
        private readonly _accessControlService: AccessControlService,
        tiService: TradingInstrumentsService,
        locationService: LocationService,
        private readonly _sessionService: SessionService,
        private readonly _copyOrdersToService: CopyOrdersToService,
        private readonly _applicationSettingsService: ApplicationSettingsService,
        private readonly _savedPositionsService: SavedPositionsService,
        private readonly _assignTemplatesService: AssignableAdjustmentTemplatesService,
        private readonly _portfoliosService: CashFlowPortfoliosService,
        private readonly _positionsAfterStateService: PositionsAfterStateService,
        private readonly _sweetPriceTrackingService: SweetPriceTrackingService,
        private readonly _priceboxPinnedRowsService: PriceboxPinnedRowsService,
        private readonly _clipboardService: ClipboardService,
        private readonly _visibleOffsetsService: VisibleOffsetsSettingsService,
        private readonly _apgOriginalClientPositionsService: ApgOriginalClientPositionsService,
        private readonly _hedgesService: HedgePositionsService
    ) {
        this.strategiesSection = new StrategiesSectionModel();

        this.positionsSection = new PositionsSectionModel(
            _changeDetector,
            this._unsubscriber,
            _positionsStateService,
            () => this.canApplyChanges(),
            _accessControlService,
            _atmStrikeService,
            _toastr,
            locationService,
            _sessionService,
            _applicationSettingsService,
            _savedPositionsService,
            _messageBus,
            'scopeID',
            tiService,
            _positionsAfterStateService,
            this._userSettingsService
        );

        this.positionsSection.setEventHandler(this as any);
        this.positionsSection.defaultsUpdated$
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(async x => {
                try {
                    await this.generateAdjustmentOrders(x)
                } catch (e) {
                    console.error(e);
                }
            })

        this.settingsSection = new SettingsSectionModel(
            _changeDetector,
            this._unsubscriber,
            _optionChainsService,
            _cashFlowStrategyTemplatesService,
            _toastr,
            this._sessionService,
            tiService,
            this._assignTemplatesService,
            _accessControlService,
            _applicationSettingsService
        );

        this.settingsSection.setContextDataProvider(this as any);

        this.priceboxSection = new PriceboxSectionModel(
            _changeDetector,
            this._unsubscriber,
            this._priceboxPinnedRowsService,
            this as any,
            this._visibleOffsetsService
        );

        AdjustmentPricingGridComponent.instanceId += 1;

        this.positionSectionUniqueId = 'ets-apg-positions' + AdjustmentPricingGridComponent.instanceId;
        this.portfolioSectionUniqueId = 'ets-apg-portfolio' + AdjustmentPricingGridComponent.instanceId;

        const availableStrats = getAvailableCashFlowStrategies(this._accessControlService);

        this.portfolioPopup = {
            isVisible: false,
            strategies: availableStrats
        };

        this._strategyCodeIndex = new StrategyCodeIndex(_lastQuoteCache);
        this._quoteIndex = new QuoteIndex(_lastQuoteCache);

        this._afterStateDetalizationService.ready$.subscribe(() => this.onAfterStateDetalizationReady())
    }

    private readonly _afterStateDetalizationService = new AfterStateDetalizationService();

    private _priceboxSectionHeaderVolatility;

    private _zonesGridSectionHeaderVolatility;

    //
    private static instanceId = 0;

    //
    private readonly _unsubscriber = new Subject<void>();

    //
    private readonly _strategyCodeIndex: StrategyCodeIndex;

    //
    private readonly _quoteIndex: QuoteIndex;

    //
    private _longRunningOperationClients = 0;

    //
    readonly positionSectionUniqueId: string;

    //
    readonly portfolioSectionUniqueId: string;

    get templatesService(): CashFlowStrategyTemplatesService {
        return this._cashFlowStrategyTemplatesService;
    }

    //
    sectionCollapsed$ = new EventEmitter<{ section: ApgGridColumn, state: boolean }>();

    @ViewChild(AdjustmentSolutionPopupComponent, {static: true})
    solutionPopupCmp: AdjustmentSolutionPopupComponent;

    //
    @ViewChild(DxScrollViewComponent, {static: false})
    scrollView: DxScrollViewComponent;

    //
    @ViewChild(ApgPortfolioRefreshDialogComponent)
    portfolioRefreshDialog: ApgPortfolioRefreshDialogComponent;

    //
    @ViewChild(ApgTrackingSyncDialogComponent)
    syncTrackingDialog: ApgTrackingSyncDialogComponent;

    @ViewChild(PriceboxMenuComponent)
    priceBoxMenu: PriceboxMenuComponent;

    @ViewChild(ZonesGridMenuComponent)
    zonesGridMenu: ZonesGridMenuComponent;

    @ViewChild(AfterStateDetalizationComponent)
    afterStateDetalizationDialog: AfterStateDetalizationComponent;

    @ViewChild(ZonesGridSettingsComponent)
    zonesGridSettingsDialog: ZonesGridSettingsComponent;

    @ViewChild(ZonesGridComponent)
    zonesGridSection: ZonesGridComponent;

    @ViewChild(ApgHedgesTotalComponent)
    hedgesTotalCmp: ApgHedgesTotalComponent;

    @ViewChild(OverrideAtmDialogComponent)
    overrideAtmDialog : OverrideAtmDialogComponent;

    showHedges = true;

    //
    @Input()
    orientation: 'left' | 'right';

    get isZonesGridAvailable(): boolean {
        return this._accessControlService
            .isSecureElementAvailable('9c43574d-4f20-423f-a68a-bbb054207aa4');
    }

    //
    portfolios: { key: string, userId: string, items: ApgPortfolio[] }[];

    //
    userContext: string;

    //
    isZonesGridLinked = true;

    //
    private _selectedPortfolio: ApgPortfolio;
    get selectedPortfolio(): ApgPortfolio {
        return this._selectedPortfolio;
    }

    @DetectSetterChanges()
    set selectedPortfolio(value: ApgPortfolio) {
        this._selectedPortfolio = value;
    }

    //
    portfolioPopup: {
        isVisible: boolean,
        strategies: CashFlowStrategy[],
        strategy?: CashFlowStrategy,
        name?: string,
        tradingInstrument?: TradingInstrument,
        mode?: 'new' | 'edit'
    };

    //
    private _positionsColCollapsed: boolean;
    get positionsColCollapsed(): boolean {
        return this._positionsColCollapsed;
    }

    set positionsColCollapsed(v: boolean) {
        this._positionsColCollapsed = v;
    }

    //
    private _settingsColCollapsed: boolean;
    get settingsColCollapsed(): boolean {
        return this._settingsColCollapsed;
    }

    set settingsColCollapsed(v: boolean) {
        this._settingsColCollapsed = v;
    }

    //
    private _strategiesColCollapsed = true;
    get strategiesColCollapsed(): boolean {
        return this._strategiesColCollapsed;
    }

    set strategiesColCollapsed(v: boolean) {
        this._strategiesColCollapsed = v;
    }

    //
    private _priceboxColCollapsed: boolean;
    get priceboxColCollapsed(): boolean {
        return this._priceboxColCollapsed;
    }

    set priceboxColCollapsed(v: boolean) {
        this._priceboxColCollapsed = v;
    }

    private _zonesGridColCollapsed = true;
    get zonesGridColCollapsed(): boolean {
        return this._zonesGridColCollapsed;
    }

    set zonesGridColCollapsed(v: boolean) {
        this._zonesGridColCollapsed = v;
    }

    //
    private _isDataLoading: boolean;
    get isDataLoading(): boolean {
        return this._isDataLoading;
    }

    @DetectSetterChanges()
    set isDataLoading(v: boolean) {
        this._isDataLoading = v;
    }

    private readonly _pendingRequestId: string[] = [];

    //
    private _positionSectionHeight = 0;

    // noinspection JSUnusedGlobalSymbols
    get leftColSettingsSectionHeight() {
        return `calc(100% - ${this._positionSectionHeight}px)`;
    }

    get fullyCollapsed(): boolean {
        return this.strategiesColCollapsed && this.priceboxColCollapsed && this.zonesGridColCollapsed;
    }

    //
    get isAtmOutdated(): boolean {
        return this.strategiesSection
            .strategies
            .flatMap(x => x.adjustmentTypes)
            .some(x => x.isAtmStrikeOutdated);
    }

    //
    readonly settingsSection: SettingsSectionModel;

    //
    readonly strategiesSection: StrategiesSectionModel;

    //
    readonly positionsSection: PositionsSectionModel;

    //
    readonly priceboxSection: PriceboxSectionModel;

    //

    @ViewChild('portfolioDelete')
    portfolioDeleteConfirmationDialog: GenericConfirmationDialogComponent;

    lastUsedSettings: AdjustmentPricingSettingsDto;


    //
    get cssForPositionsSection(): string[] {
        const css = [];

        if (this.showHedges) {
            css.push('show-hedges');
        }

        if (this.positionsSection.savedStateLoaded) {
            css.push('saved-data-badge');
        }

        if (!this.showPortfoliosSection) {
            css.push('full');
        }

        return css;
    }

    //
    get cssForSettingsSection(): string[] {
        const css = [];

        if (this.positionsSection.savedStateLoaded) {
            css.push('saved-data-badge');
        }

        return css;
    }

    //
    get isOwnUserContext(): boolean {
        return this.userContext === this._sessionService.sessionData.userId;
    }

    //
    get showPortfoliosSection(): boolean {
        return this._accessControlService.isSecureElementAvailable('a7fc7492-2509-455c-97c3-4d5b257a50a1');
    }

    //
    get canDeletePortfolio(): boolean {
        return this.selectedPortfolio && this.selectedPortfolio.id !== DefaultApgPortfolioId
            && this.isOwnUserContext;
    }

    //
    get canCreatePortfolio(): boolean {
        return this.isOwnUserContext;
    }

    //
    get canEditPortfolio(): boolean {
        return this.canDeletePortfolio;
    }

    //
    get canRefreshPortfolio(): boolean {
        return !isVoid(this.selectedPortfolio) && !this.isOwnUserContext;
    }

    get shouldApplyZonesGrid(): boolean {
        return !isVoid(this.zonesGridSection) && this.isZonesGridLinked
            && !isVoid(this.zonesGridSection.loadedRow);
    }

    //
    @DetectMethodChanges()
    ngOnInit(): void {

        this._messageBus.of<StrategyPriceDto[]>('StrategyPriceDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onStrategyPrice(x.payload));

        this._messageBus.of<AtmStrikeChangedEvent>('AtmStrikeChangedEvent')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onAtmStrikeChanged(x.payload));

        this._messageBus.of<QuoteDto[]>('QuoteDto')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onQuote(x.payload));

        this._messageBus.of<TrackingStartedMessage>('TrackingStartedMessage')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onTrackingStartedConfirmation(x.payload));

        this._messageBus.of<TrackingStoppedMessage>('TrackingStoppedMessage')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onTrackingStoppedConfirmation(x.payload));

        this._messageBus.of<TrackingRequestErrorMessage>('TrackingRequestErrorMessage')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onTrackingRequestError(x.payload));

        this._messageBus.of<TrackingTemplateUpdated>('TrackingTemplateUpdated')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onTrackingTemplateUpdated(x.payload));

        this._messageBus.of<TrackingNotificationSettingsUpdated>('TrackingNotificationSettingsUpdated')
            .pipe(takeUntil(this._unsubscriber))
            .subscribe(x => this.onTrackingNotificationSettingsUpdated(x.payload));

        this._messageBus.of<ApgPortfolioStateChangedDto>('ApgPortfolioStateChangedDto')
            .pipe(
                takeUntil(this._unsubscriber),
                filter(x => x.payload.userId !== this._sessionService.sessionData.userId)
            )
            .subscribe(x => this.onSubordinatePortfolioStateChanged(x.payload));

        this._messageBus.of<ApgPortfolioStateChangedDto>('Apg.ClientSavedPositionsChanged')
            .pipe(
                takeUntil(this._unsubscriber),
            )
            .subscribe(x => this._changeDetector.detectChanges());

        this._messageBus.of<ApgPortfolioStateChangedDto>('Apg.LastUsedTemplateChanged')
            .pipe(
                takeUntil(this._unsubscriber),
            )
            .subscribe(x => this._changeDetector.detectChanges());

        const mediaQuery = window.matchMedia('( max-width: 1024px )');

        if (mediaQuery.matches) {
            this.priceboxColCollapsed = true;
        }

        if (!isVoid(this.orientation)) {
            this._positionsColCollapsed = false;
            this._strategiesColCollapsed = true;
            this._priceboxColCollapsed = true;
        }


        this._messageBus.of<SweetPriceAvailableDto>('SweetPriceAvailableDto')
            .pipe(
                takeUntil(this._unsubscriber),
            )
            .subscribe(x => this.onSweetPriceAvailable(x.payload));


        this._messageBus.of<GetAdjustmentSolutionsReply>('GetAdjustmentSolutionsReply')
            .pipe(
                takeUntil(this._unsubscriber)
            )
            .subscribe(async x => {
                await this.onDataLoaded(x.payload);
            });
    }

    onSweetPriceAvailable(payload: SweetPriceAvailableDto): void {
        let exp = makeGuiFriendlyExpirationDate(payload.expiration);
        const msg = `Expiration: ${exp}, Price: $${payload.price}, Adjustment: ${payload.adjustment}`;
        this._toastr.info(msg, 'Sweet Price Available!');
    }

    //
    private onSubordinatePortfolioStateChanged(payload: ApgPortfolioStateChangedDto): void {
        const userPortfolios = this.getPortfoliosByUser(payload.userId);

        const updatedPortfolio = userPortfolios.find(x => x.id === payload.apgPortfolioId);

        if (isVoid(updatedPortfolio)) {
            return;
        }

        if (updatedPortfolio === this.selectedPortfolio) {
            this._changeDetector.detectChanges();
            this._portfoliosService.savePortfolios(userPortfolios);
        }
    }

    //
    @DetectMethodChanges({isAsync: true})
    async ngAfterViewInit(): Promise<void> {

        // Fill portfolios

        this.isDataLoading = true;

        try {

            const grps = this._sessionService.sessionData.users
                .sort((a, b) => a.userName.localeCompare(b.userName))
                .map(user => {

                    const grp = {
                        key: user.userName,
                        userId: user.userId,
                        items: []
                    };

                    const cfg: ServiceConfiguration = {
                        orientation: this.orientation,
                        userId: user.userId,
                        userName: user.userName
                    };

                    this._portfoliosService.configure(cfg);

                    const pfs = this._portfoliosService.getPortfolios();

                    grp.items.push(...pfs);

                    return grp;
                });

            const ownUser = this.getUserObject(this._sessionService.sessionData.userId);

            await this.configureServices({
                orientation: this.orientation,
                userId: ownUser.userId,
                userName: ownUser.userName
            });

            const pfs = this._portfoliosService.getPortfolios();

            const ownGrp = {
                key: ownUser.userName,
                userId: ownUser.userId,
                items: pfs
            }

            grps.unshift(ownGrp);

            this.portfolios = grps;

            if (!this._sessionService.isSuperUser) {
                await this.setDefaultPortfolio();
            }

            this.priceBoxMenu.settingsSaved$
                .pipe(takeUntil(this._unsubscriber))
                .subscribe(_ => this._changeDetector.detectChanges());

        } finally {

            this.isDataLoading = false;

        }


    }

    private async configureServices(cfg: ServiceConfiguration) {
        this._priceboxPinnedRowsService.configure(cfg);
        this._portfoliosService.configure(cfg);
        this._savedPositionsService.configure(cfg);
        this._positionsStateService.configure(cfg);
        this._copyOrdersToService.configure(cfg);
        this._positionsAfterStateService.configure(cfg);
        await this._cashFlowStrategyTemplatesService.configure(cfg);
    }

    //
    ngOnDestroy(): void {

        if (this._unsubscriber) {
            this._unsubscriber.next();
            this._unsubscriber.complete();
        }

        this.resetStateFull();
        this.resetZonesGrid();
    }


    @DetectMethodChanges()
    showPopup(pricings: CashFlowAdjustment | CashFlowAdjustment[], source: 'PriceBox' | 'Zones Grid') {

        if (isVoid(pricings)) {
            this._toastr.error('Selected Adjustment Cannot be Identified');
            return;
        }

        let p: CashFlowAdjustment[];

        if (!Array.isArray(pricings)) {
            p = [pricings];
        } else {
            p = pricings;
        }

        const hasNa = p.some(x => isNullOrUndefined(x.price) || isNaN(x.price));

        if (hasNa) {
            if (!this._sessionService.isSuperUser) {
                return;
            }
        }

        const strategyType = this.settingsSection.isTwoSideStrategy
            ? 'double'
            : 'single';

        const pfId = this._selectedPortfolio
            ? this._selectedPortfolio
            : null;

        this.solutionPopupCmp.show(pfId, p, strategyType, source);
    }

    get shouldRequestOverrideAtm() : boolean {
        const mode = this._applicationSettingsService.adjustmentPricingGrid.atmWarningMode;

        if (mode !== 'Popup') {
            return false;
        }

        if (this.positionsSection.liveStrikesUpdate) {

            if (this.positionsSection.overrideAtmLiveMode === 'market') {
                return false;
            }

            return !isValidNumber(this.settingsSection.globalSettings.overrideAtm);

        } else {

            return !isValidNumber(this.settingsSection.globalSettings.overrideAtm);

        }
    }

    @DetectMethodChanges({isAsync: true})
    async applyClicked(forceApply?: boolean): Promise<void> {

        if (!this.canApplyChanges()) {
            this._toastr.warning('No changes to apply');
            return;
        }

        const positionsErrors = this.positionsSection.validate();

        if (positionsErrors.length > 0 && !forceApply) {
            positionsErrors.forEach(e => this._toastr.error(e, 'Positions'));
            return;
        }

        const errors = this.settingsSection.validate();
        if (!isVoid(errors)) {
            errors.forEach(err => this._toastr.error(err, 'Settings'));
            return;
        }

        if (this.shouldRequestOverrideAtm) {

            try {

                const overrideAtm = await this.overrideAtmDialog.show();

                if (!isValidNumber(overrideAtm)) {
                    this._toastr.error('Incorrect Override Atm', 'Error');
                    throw new Error();
                }

                this.settingsSection.globalSettings.overrideAtm = overrideAtm;

            } catch {
                if (this.positionsSection.liveStrikesUpdate) {
                    this.positionsSection.overrideAtmLiveMode = 'market';
                }
            } finally {
                if (this.positionsSection.liveStrikesUpdate) {
                    if (isValidNumber(this.settingsSection.globalSettings.overrideAtm)) {
                        this.positionsSection.overrideAtmLiveMode = 'custom';
                    }
                }
            }
        } else {
            if (this.positionsSection.liveStrikesUpdate) {
                if (isValidNumber(this.settingsSection.globalSettings.overrideAtm)) {
                    this.positionsSection.overrideAtmLiveMode = 'custom';
                }
            }
        }

        this.isDataLoading = true;

        try {

            await this.loadData();

        } catch (e) {

            this._toastr.error('"Load Adjustments Pricing Data" operation completed with errors');

            if (!this.shouldApplyZonesGrid) {
                this.isDataLoading = false;
            }
        }
    }


    private async loadData(): Promise<void> {

        const adjustmentPricingSettings = this.getAdjustmentPricingSettingsObject('Price Box');

        if (!isVoid(adjustmentPricingSettings.errors)) {
            adjustmentPricingSettings.errors.forEach(x => this._toastr.error(x));
            return;
        }

        const requestId = getShortUUID();

        this._pendingRequestId.push(requestId);

        const qry = new GetAdjustmentSolutions(requestId, adjustmentPricingSettings.settings);

        this.resetStateFull();

        try {
            await this._shellClient.processCommand(qry);

            if (!isVoid(adjustmentPricingSettings)) {
                if (!isVoid(adjustmentPricingSettings.settings)) {
                    this.priceboxSection.lastUsedSettings = adjustmentPricingSettings.settings;
                    this.lastUsedSettings = adjustmentPricingSettings.settings[0];
                }
            }
        } catch {
            const ix = this._pendingRequestId.indexOf(requestId);
            if (ix >= 0) {
                this._pendingRequestId.splice(ix, 1);
            }
        }

        if (this.shouldApplyZonesGrid) {
            await this.loadDataZonesGrid();
        }
    }


    private onStrategyPrice(prices: StrategyPriceDto[]) {

        // let haveToUpdate: boolean;
        let updates = this._strategyCodeIndex.onStrategyPrice(prices)

        if (isVoid(updates)) {
            return;
        }

        // this.strategiesSection.onStrategyPriceUpdated(updates);

        // TODO: provide layout id
        this._messageBus.publishAsync({
            topic: 'Apg.StrategyPriceUpdated',
            payload: updates,
        });
    }


    private resetStateFull() {

        this.positionsSection.reset();

        this.settingsSection.reset();

        this.resetPricingData();
    }

    private resetZonesGrid() {
        if (!this.zonesGridSection) {
            return;
        }

        this.zonesGridSection.reset();
        this._quoteIndex.resetZones();
        this._strategyCodeIndex.resetZones();
    }

    private resetPricingData() {

        this._quoteIndex.reset();

        this._strategyCodeIndex.reset();

        this.strategiesSection.reset();

        this.priceboxSection.reset();

        this.solutionPopupCmp.reset();
    }


    getPriceColorClass(price: number) {
        if (!price) {
            return undefined;
        }

        if (price > 0) {
            return 'credit';
        }

        if (price < 0) {
            return 'debit';
        }

        return undefined;
    }


    async getRefreshPortfolioCss(): Promise<string[]> {

        if (isVoid(this.selectedPortfolio)) {
            return null;
        }

        if (this.isDataLoading) {
            return null;
        }

        const hasTheirChanges = this.hasTheirChanges();

        const hasOwnChanges = this.hasOwnChanges();

        const result = [];

        if (hasOwnChanges) {
            result.push('own-changes');
        }

        if (hasTheirChanges) {
            result.push('their-changes');
        }

        return result;
    }

    hasTheirChanges() {
        if (this.userContext === this._sessionService.sessionData.userId) {
            return false;
        }

        if (isVoid(this.selectedPortfolio)) {
            return false;
        }

        if (!this.settingsSection.isTemplateSelected) {
            return false;
        }

        if (this._longRunningOperationClients > 0) {
            return false;
        }


        const currentTemplate = this._apgOriginalClientPositionsService
            .getTheirCurrentTemplate(this._selectedPortfolio);

        const snapshotTemplate = this._apgOriginalClientPositionsService
            .getTheirSnapshotTemplate(this._selectedPortfolio);

        const templatesEqual = currentTemplate?.templateId === snapshotTemplate?.templateId
            && currentTemplate?.strategy === snapshotTemplate?.strategy
            && currentTemplate.underlying === snapshotTemplate.underlying;

        if (!templatesEqual) {
            return true;
        }

        const originalPositions = this._apgOriginalClientPositionsService.getTheirOriginalPositions(
            this._selectedPortfolio,
        ) || [];

        const currentPositions = this._apgOriginalClientPositionsService
            .getTheirCurrentPositions(this._selectedPortfolio) || [];

        if (isVoid(currentPositions)) {
            return false;
        }

        const originalIds = originalPositions
            .flatMap(x => x.map(y => `${y.ticker}|${y.qty}`))
            .sort();

        const currentIds = currentPositions
            .flatMap(x => x.map(y => `${y.ticker}|${y.qty}`))
            .sort();

        const areEqual = arraysEqual(originalIds, currentIds);

        return !areEqual;
    }


    hasOwnChanges() {
        if (this.userContext === this._sessionService.sessionData.userId) {
            return false;
        }

        if (isVoid(this.selectedPortfolio)) {
            return false;
        }

        if (!this.settingsSection.isTemplateSelected) {
            return false;
        }

        if (this._longRunningOperationClients > 0) {
            return false;
        }

        const theirTemplate = this._apgOriginalClientPositionsService
            .getTheirSnapshotTemplate(this._selectedPortfolio);

        const currentTemplate = this.settingsSection.globalSettings.selectedTemplate;

        const templatesEqual = theirTemplate?.templateId === currentTemplate?.templateId
            && theirTemplate?.underlying === currentTemplate?.underlying
            && theirTemplate?.strategy === currentTemplate?.strategyName

        if (!templatesEqual) {
            return true;
        }

        const their = this._apgOriginalClientPositionsService.getTheirOriginalPositions(
            this._selectedPortfolio,
        ) || [];

        const currentPositions = this.positionsSection.positionsData?.map(x => x.positions) || [];

        const originalIds = their
            .flatMap(x => x.map(y => `${y.ticker}|${y.qty}`))
            .sort();

        const currentIds = currentPositions
            .flatMap(x => x.map(y => `${y.getTicker()}|${y.qty}`))
            .sort();

        const areEqual = arraysEqual(originalIds, currentIds);

        return !areEqual;
    }


    isNullOrUndefined(value: any) {
        return isNullOrUndefined(value) || isNaN(value);
    }


    onAtmStrikeChanged(payload: AtmStrikeChangedEvent): void {

        let needUpdate = false;

        this.strategiesSection.strategies.forEach(str => {
            str.adjustmentTypes.forEach(adjType => {

                if (adjType.isEmpty) {
                    return;
                }

                if (adjType.underlying !== payload.underlying) {
                    return;
                }

                const oldValue = adjType.isAtmStrikeOutdated;

                adjType.isAtmStrikeOutdated = adjType.atmStrike !== payload.currentAtm;

                if (adjType.isAtmStrikeOutdated != oldValue) {
                    needUpdate = true;
                }
            });
        });

        this.positionsSection.updateAtmDistance();

        if (needUpdate) {
            this._changeDetector.detectChanges();
        }
    }


    // [!] Implicitly used as event-handler in subcomponent
    @DetectMethodChanges({isAsync: true})
    async onStrategyChanged(x: CashFlowStrategy): Promise<void> {

        await this.onLongRunningOperationChanged(true);

        //hack to let loading panel render
        await delay(250);

        this.resetPricingData();
        this.resetZonesGrid();

        try {

            if (this.selectedPortfolio.id !== DefaultApgPortfolioId) {

                const activeTpl = this.settingsSection.globalSettings.selectedTemplate;

                const sameSymbol = this.selectedPortfolio.asset === activeTpl.underlying;

                const sameStrategy = this.selectedPortfolio.strategy === x;

                if (!sameSymbol || !sameStrategy) {

                    const noPortfolio = getDefaultPortflio(
                        this.userContext,
                        this._sessionService.sessionData.users.find(x => x.userId === this.userContext).userName,
                        {
                            asset: activeTpl.underlying,
                            strategy: x
                        }
                    );

                    const userPortfolios = this.getCurrentUserPortfolios();

                    console.assert(!isVoid(userPortfolios));

                    const ix = userPortfolios
                        .findIndex(x => {
                            return x.id === DefaultApgPortfolioId && x.userId === this.userContext;
                        });

                    if (ix >= 0) {
                        userPortfolios[ix] = noPortfolio;
                    } else {
                        userPortfolios.unshift(noPortfolio)
                    }

                    this.selectedPortfolio = noPortfolio;
                }
            }

            await this.positionsSection.onStrategyChanged(x);

        } catch (e) {

            this._toastr.error('"Create Before-state positions" operation completed with errors');

        } finally {

            setTimeout(() => {
                this.onLongRunningOperationChanged(false);
            }, 1000);
        }
    }


    async tryApplyChangesLive(): Promise<void> {

        if (!this.positionsSection.liveStrikesUpdate) {
            return;
        }

        if (this.canApplyChanges()) {
            await this.applyClicked();
        }
    }


    @DetectMethodChanges()
    onSymbolChanged(x: TradingInstrument) {

        this.resetPricingData();
        this.resetZonesGrid();

        if (!isVoid(x)) {
            this._atmStrikeService.watch(this.settingsSection.underlying);
        }

        this.positionsSection.onSymbolChanged(x);

    }


    @DetectMethodChanges()
    async onLongRunningOperationChanged(x: boolean): Promise<void> {

        if (x) {

            this._longRunningOperationClients++;
            this.isDataLoading = true;

        } else {

            this._longRunningOperationClients--;

            if (this._longRunningOperationClients <= 0) {

                this.isDataLoading = false;

                this._longRunningOperationClients = 0;

                await this.tryApplyChangesLive();
            }
        }
    }


    @DetectMethodChanges()
    toggleCollapsed(col: ApgGridColumn) {
        let state: boolean;

        if (col === 'positions') {
            state = this.positionsColCollapsed = !this.positionsColCollapsed;
        } else if (col === 'strategies') {
            state = this.strategiesColCollapsed = !this.strategiesColCollapsed;
        } else if (col === 'pricebox') {
            state = this.priceboxColCollapsed = !this.priceboxColCollapsed;
        } else if (col === 'settings') {
            state = this.settingsColCollapsed = !this.settingsColCollapsed;
        } else if (col === 'zones-grid') {
            state = this.zonesGridColCollapsed = !this.zonesGridColCollapsed;
        }

        this.sectionCollapsed$.emit({section: col, state});
    }


    @DetectMethodChanges()
    collapseAllBut(col: ApgGridColumn) {
        if (col === 'strategies') {

            if (this.positionsColCollapsed && this.priceboxColCollapsed) {

                this.positionsColCollapsed = false;
                this.settingsColCollapsed = false;
                this.strategiesColCollapsed = false;
                this.priceboxColCollapsed = false;

            } else {
                this.positionsColCollapsed = true;
                this.settingsColCollapsed = true;
                this.strategiesColCollapsed = false;
                this.priceboxColCollapsed = true;
            }

        } else if (col === 'pricebox') {

            if (this.positionsColCollapsed && this.strategiesColCollapsed) {

                this.positionsColCollapsed = false;
                this.settingsColCollapsed = false;
                this.strategiesColCollapsed = false;
                this.priceboxColCollapsed = false;

            } else {
                this.positionsColCollapsed = true;
                this.settingsColCollapsed = true;
                this.strategiesColCollapsed = true;
                this.priceboxColCollapsed = false;
            }

        }
    }


    onQuote(quotes: QuoteDto[]) {
        const hasUpdates = this._quoteIndex.onQuote(quotes);
        this._messageBus.publishAsync({
            topic: 'Apg.QuotesUpdated',
            payload: {}
        });
    }


    toggleSettingsSectionCollapsed(): void {
        this.toggleCollapsed('settings');
    }


    canApplyChanges(): boolean {

        const positionsHasChanges = this.positionsSection.hasChanges;

        const settingsHasChanges = this.settingsSection.hasChanges;

        const atmOutdated = this.strategiesSection
            .strategies
            .flatMap(x => x.adjustmentTypes)
            .some(x => x.isAtmStrikeOutdated);

        return positionsHasChanges || settingsHasChanges || atmOutdated;
    }


    // DO NOT REMOVE! Implicitly called
    @DetectMethodChanges({isAsync: true})
    async onSecondSpreadChanged(x: { include: boolean; strategyName: CashFlowStrategy }): Promise<void> {

        await this.onLongRunningOperationChanged(true);

        try {

            await this.positionsSection.onSecondSpreadChanged(x.include, x.strategyName);

        } catch (e) {

            this._toastr.error('"Create Before-state positions" operation completed with errors');

        } finally {

            setTimeout(() => {
                this.onLongRunningOperationChanged(false);
            }, 1000);

        }
    }


    // DO NOT REMOVE! Implicitly called
    @DetectMethodChanges({isAsync: true})
    async onSecondProtectiveOptionChanged(x: { include: boolean, strategyName: CashFlowStrategy }): Promise<void> {

        await this.onLongRunningOperationChanged(true);

        try {

            await this.positionsSection.onSecondProtectiveOptionChanged(x.include, x.strategyName);

        } catch (e) {

            this._toastr.error('"Create Before-state positions" operation completed with errors');

        } finally {

            setTimeout(() => {
                this.onLongRunningOperationChanged(false);
            }, 1000);

        }
    }


    isEmptyCell(expiration: CashFlowAdjustment): boolean {

        if (isVoid(expiration)) {
            return true;
        }

        if (expiration.isEmpty) {
            return true;
        }

        if (expiration.adjustmentTypeObj && expiration.adjustmentTypeObj.isEmpty) {
            return true;
        }

        return !isValidNumber(expiration.price);
    }


    // noinspection JSUnusedGlobalSymbols
    onPositionsRestored(args: PositionsRestoredEventArgs): void {
        this.settingsSection.onPositionsRestored(args);
    }


    // noinspection JSUnusedGlobalSymbols
    getSavePortfolioButtonText(): 'New' | 'Save' {
        const shouldNew = isVoid(this.selectedPortfolio) || this.selectedPortfolio.id !== DefaultApgPortfolioId;
        return shouldNew ? 'New' : 'Save';
    }


    @DetectMethodChanges({isAsync: true})
    async onPortfolioSelected(args: DxValueChanged<ApgPortfolio>): Promise<void> {

        this.positionsSection.overrideAtmLiveMode = null;
        this.settingsSection.globalSettings.overrideAtm = null;

        if (isVoid(args.value)) {
            console.trace('portfolio is null', args);
            this.userContext = undefined;
            this.selectedPortfolio = undefined;
            return;
        }

        if (this.userContext !== args.value.userId) {

            console.log(`[apg] user context changed: prev=${this.userContext}, curr=${args.value.userId}`);

            this.userContext = args.value.userId;

            const userObject = this.getUserObject(this.userContext);

            await this.configureServices({
                orientation: this.orientation,
                userId: userObject.userId,
                userName: userObject.userName
            });

        }

        if (!args.event) {
            this.settingsSection.globalSettings.selectedPortfolio = args.value;
            return;
        }

        await this.hedgesTotalCmp.onPortfolioSelected(args.value);

        if (args.value.id === DefaultApgPortfolioId) {

            const override = args.event && args.event === 'ets.override';

            args.value.asset = override
                ? args.value.asset
                : this._applicationSettingsService.adjustmentPricingGrid.defaultUnderlying;

            args.value.strategy = override
                ? args.value.strategy
                : this._applicationSettingsService.adjustmentPricingGrid.defaultStrategy;

        }

        await this._sweetPriceTrackingService.loadPortfolioTrackingData(
            this.userContext,
            args.value.id
        );

        this.positionsSection.onPortfolioSelected(args.value);

        this.settingsSection.onPortfolioSelected(args.value);

        this.settingsSection.setDefaultTemplate(
            args.value.asset,
            args.value.strategy
        );
    }


    //
    @DetectMethodChanges({isAsync: true})
    async deleteSelectedPortfolio(): Promise<void> {

        if (!this.selectedPortfolio) {
            return;
        }

        if (this.selectedPortfolio.id === DefaultApgPortfolioId) {
            return;
        }

        try {
            await this.portfolioDeleteConfirmationDialog.show();
        } catch {
            return;
        }

        const userPortfolios = this.getCurrentUserPortfolios();

        const ix = userPortfolios.indexOf(this.selectedPortfolio);

        if (ix >= 0) {
            userPortfolios.splice(ix, 1);
            this._userSettingsService.removeKeysMatchingPattern(this.selectedPortfolio.id);
        }

        // hack to update portfolios data source cause it is immutable
        this.portfolios = this.portfolios.slice();

        this._portfoliosService.savePortfolios(userPortfolios);

        this._toastr.success(`[${this.selectedPortfolio!.name}] portfolio has been deleted`, 'Portfolios');

        const noPortfolio = userPortfolios.find(x => x.id === DefaultApgPortfolioId);

        if (noPortfolio) {
            this._selectedPortfolio = noPortfolio;
            await this.onPortfolioSelected({value: noPortfolio, event: 'ets'});
        }
    }


    //
    @DetectMethodChanges()
    showPortfolioPopup() {

        this.portfolioPopup.name = null;

        this.portfolioPopup.tradingInstrument = this.settingsSection.globalSettings.tradingInstrument;

        this.portfolioPopup.strategy = this.settingsSection.globalSettings.selectedStrategy;

        this.portfolioPopup.mode = 'new';

        this.portfolioPopup.isVisible = true;
    }

    @DetectMethodChanges()
    editPortfolio() {

        this.portfolioPopup.name = this.selectedPortfolio?.name;

        this.portfolioPopup.tradingInstrument = null;

        this.portfolioPopup.strategy = null;

        this.portfolioPopup.mode = 'edit';

        this.portfolioPopup.isVisible = true;
    }


    //
    @DetectMethodChanges({isAsync: true})
    async saveNewApgPortfolio(): Promise<void> {

        if (isVoid(this.portfolioPopup.name)) {
            this._toastr.error('Provide portfolio name');
            return;
        }

        const userPortfolios = this.getCurrentUserPortfolios();

        if (this.portfolioPopup.mode === 'new') {
            if (isVoid(this.portfolioPopup.tradingInstrument)) {
                this._toastr.error('Provide portfolio asset');
                return;
            }

            if (isVoid(this.portfolioPopup.strategy)) {
                this._toastr.error('Provide portfolio strategy');
                return;
            }

            const currentUser = this.getUserObject(this.userContext);

            const portfolio: ApgPortfolio = {
                id: getShortUUID(),
                name: this.portfolioPopup.name,
                asset: this.portfolioPopup.tradingInstrument.ticker,
                strategy: this.portfolioPopup.strategy,
                userId: currentUser.userId,
                userName: currentUser.userName
            };

            userPortfolios.push(portfolio);

            this._selectedPortfolio = portfolio;

            await this.onPortfolioSelected({previousValue: undefined, value: this._selectedPortfolio, event: 'ets'});

        } else {
            this._selectedPortfolio.name = this.portfolioPopup.name;
        }

        this._portfoliosService.savePortfolios(userPortfolios);

        this.portfolioPopup.isVisible = false;

        // hack to update portfolios data source cause it is immutable
        const portfoliosCopy = this.portfolios.slice();

        setTimeout(() => {
            this.portfolios = portfoliosCopy;
            this._changeDetector.detectChanges();

            this._messageBus.publish({
                topic: 'Apg.PortfoliosUpdated',
                payload: {
                    portfolios: portfoliosCopy
                }
            });
        }, 0);
    }


    //
    @DetectMethodChanges()
    onPortfolioPopupClosed() {
        this.portfolioPopup.tradingInstrument = null;
        this.portfolioPopup.name = null;
        this.portfolioPopup.strategy = null;
        this.portfolioPopup.isVisible = false;
    }


    @DetectMethodChanges({isAsync: true})
    async refreshPortfolio(portfolio: ApgPortfolio) {

        let selected: 'Former' | 'Current';

        try {
            selected = await this.portfolioRefreshDialog.show(
                portfolio,
                this
            );

        } catch (e) {
            if (e) {
                this._toastr.error(e);
            }
        }

        if (isVoid(selected)) {
            return;
        }

        try {

            const selectedPortfolio = this.selectedPortfolio;
            await this.onPortfolioSelected({value: selectedPortfolio, event: 'ets'});

        } catch {

            this._toastr.error('"Refresh Portfolio" operation completed with errors');

        } finally {

            this.isDataLoading = false;

        }
    }


    private async setDefaultPortfolio(underlying?: string, strategy?: CashFlowStrategy): Promise<void> {

        this.userContext = this._sessionService.sessionData.userId;

        const userPortfolios = this.getCurrentUserPortfolios();

        const def = userPortfolios.find(x => x.id === DefaultApgPortfolioId);

        if (isVoid(def)) {
            return;
        }

        if (!isVoid(underlying)) {
            def.asset = underlying;
        }

        if (!isVoid(strategy)) {
            def.strategy = strategy;
        }

        this._selectedPortfolio = def;

        const event = (underlying || strategy) ? 'ets.override' : 'ets';

        await this.onPortfolioSelected({value: def, event: event});
    }


    private getCurrentUserPortfolios(): ApgPortfolio[] {
        return this.getPortfoliosByUser(this.userContext);
    }


    private getPortfoliosByUser(userId: string): ApgPortfolio[] {
        const pfs = this.portfolios.find(x => x.userId === userId);
        if (!isVoid(pfs)) {
            return pfs.items;
        }
        return [];
    }


    private getUserObject(userId: string): UserDto {

        if (this._sessionService.sessionData.userId === userId) {

            const ownUserName = this._sessionService.sessionData.firstName + ' ' + this._sessionService.sessionData.lastName[0] + '.';

            return {
                userId,
                userName: ownUserName
            };

        } else {

            return this._sessionService.sessionData.users.find(x => x.userId === userId);
        }
    }


    // noinspection JSUnusedGlobalSymbols
    async onStopTrackingRequest(): Promise<void> {
        try {

            this.isDataLoading = true;
            await this._sweetPriceTrackingService.stopTracking(
                this.userContext,
                this.selectedPortfolio
            );

        } catch (e) {
            console.error(e);
            this.isDataLoading = false;
        }
    }


    async onStartTrackingRequest(): Promise<string> {

        const beforePositions: {
            strategy: CashFlowStrategy,
            positions: BeforePositionDto[]
        }[] = this.positionsSection.getBeforePositions();

        if (isVoid(beforePositions) || beforePositions.some(pd => pd.positions.some(pos => isVoid(pos.qty)))) {
            this._toastr.error('Incorrect positions data configuration');
            return null;
        }

        let sweetPriceSettings: SweetPriceSettings;

        try {

            sweetPriceSettings = this._sweetPriceTrackingService.getSweetPriceSettings(
                this.userContext,
                this.selectedPortfolio.id
            );

            this._sweetPriceTrackingService.validateSweetPriceSettings(
                sweetPriceSettings,
                {
                    strategy: this.settingsSection.globalSettings.selectedStrategy,
                    underlying: this.settingsSection.globalSettings.underlying,
                    priceZoneAtmNotificationFilterEnabled: false
                }
            );

        } catch (e) {
            this._toastr.error(e);
            throw e;
        }

        console.assert(!isVoid(sweetPriceSettings))

        try {

            this.isDataLoading = true;

            const positions = beforePositions.map(x => x.positions);

            const hedges = await this._hedgesService.getHedgePositions(this.selectedPortfolio);

            const expirationSmartModeSettings = this._applicationSettingsService
                .adjustmentPricingGrid
                .expirationSmartMode;

            await this._sweetPriceTrackingService.startTracking(
                this.userContext,
                this.selectedPortfolio,
                positions,
                sweetPriceSettings,
                expirationSmartModeSettings,
                hedges
            );

        } catch {
            this.isDataLoading = false;
        }
    }


    private getAdjustmentPricingSettingsObject(ctx: 'Price Box' | 'Zones Grid'): {
        errors: string[],
        settings: AdjustmentPricingSettingsDto[]
    } {

        const errors = this.settingsSection.validate();

        if (!isVoid(errors)) {

            return {
                errors,
                settings: []
            }
        }

        const result = {
            errors: [],
            settings: []
        };

        const beforePositions: {
            strategy: CashFlowStrategy,
            positions: BeforePositionDto[]
        }[] = this.positionsSection.getBeforePositions();

        if (isVoid(beforePositions)) {
            console.error('PositionsData is empty');
            result.errors.push('Positions data is not valid');
            return result;
        }

        if (beforePositions.some(pd => pd.positions.some(pos => isVoid(pos.qty)))) {
            result.errors.push('Positions data is not valid');
            console.error('Positions Data has bad qty');
            return result;
        }

        const strategySettings: ICashFlowAdjustmentSettingsTemplate
            = this.settingsSection.getSettings();

        if (isVoid(strategySettings)) {
            result.errors.push('Strategy settings are Not Valid');
            return result;
        }

        const template = this.settingsSection.globalSettings.selectedTemplate;
        let templateId = 'N/A';
        let templateName = 'N/A';

        if (!isVoid(template)) {
            templateId = template.templateId;
            templateName = template.templateName;
        }

        const adjustmentPricingSettings: AdjustmentPricingSettingsDto[]
            = strategySettings.settings.map(s => {

            const beforeState = beforePositions
                .find(x => x.strategy === s.strategyName)?.positions;

            if (isVoid(beforeState)) {
                console.error(`Cannot Determine Before State for '${s.strategyName}' strategy`)
                return null;
            }

            const theoTarget = strategySettings.globalSettings.theoreticalPriceTarget;
            const theoMode = strategySettings.globalSettings.theoreticalPriceMode;

            let useTheo = false;

            if (!isVoid(theoTarget) && !isVoid(theoMode) && theoMode === 'All Theoretical') {

                if (theoTarget === 'All') {
                    useTheo = true;
                } else if (theoTarget === 'Price Box') {
                    useTheo = ctx === 'Price Box';
                } else if (theoTarget === 'Zones Grid') {
                    useTheo = ctx === 'Zones Grid';
                }

                if (useTheo) {
                    const iv = this.settingsSection.globalSettings.theoreticalPriceIv || 0;
                    beforeState.forEach(p => p.ticker += ` !${iv}`);
                } else {
                    beforeState
                        .filter(p => p.ticker.endsWith(' !'))
                        .forEach(p => {
                            const ix = p.ticker.indexOf(' !');
                            const ticker = p.ticker.substring(0, ix);
                            p.ticker = ticker;
                        });
                    strategySettings.globalSettings.theoreticalPriceMode = null;
                    strategySettings.globalSettings.theoreticalPriceIv = null;
                }
            }


            const dto: AdjustmentPricingSettingsDto = {
                templateId,
                templateName,

                strategy: s.strategyName,
                underlying: this.settingsSection.globalSettings.underlying,
                comboId: null,
                priceToDestination: strategySettings.globalSettings.priceToDestination,
                priceToOpen: strategySettings.globalSettings.priceToOpen,
                beforeState,
                isStrategyAdvancedMode: this.settingsSection.globalSettings.isStrategyAdvancedMode,
                isDynamicOffsets: strategySettings.globalSettings.isDynamicOffsets,
                theoreticalPriceIv: strategySettings.globalSettings.theoreticalPriceIv,
                theoreticalPriceMode: strategySettings.globalSettings.theoreticalPriceMode,
                theoreticalPriceIvList: '',

                // spread
                spreadOffset: s.strategySettings.spreadOffset,
                spreadWidth: s.strategySettings.spreadWidth,
                spreadRollXDaysBeforeExpiration: s.strategySettings.spreadRollXDaysBeforeExpiration,
                spreadRollToDaysToExp: s.strategySettings.spreadRollToDaysToExp,
                spreadOverrideRollToDaysToExp: s.strategySettings.spreadOverrideRollToDaysToExp,
                spreadEvergreenOverrideRollToDaysToExp: s.strategySettings.spreadEvergreenOverrideRollToDaysToExp,

                // second spread
                secondSpreadOffset: s.strategySettings.secondSpreadOffset,
                secondSpreadWidth: s.strategySettings.secondSpreadWidth,
                secondSpreadRollXDaysBeforeExpiration: s.strategySettings.secondSpreadRollXDaysBeforeExpiration,
                secondSpreadRollToDaysToExp: s.strategySettings.secondSpreadRollToDaysToExp,
                secondSpreadOverrideRollToDaysToExp: s.strategySettings.secondSpreadOverrideRollToDaysToExp,
                secondSpreadEvergreenOverrideRollToDaysToExp: s.strategySettings.secondSpreadEvergreenOverrideRollToDaysToExp,

                // protective option
                protectiveOptionOffset: s.strategySettings.protectiveOptionOffset,
                protectiveOptionRollXDaysBeforeExpiration: s.strategySettings.protectiveOptionRollXDaysBeforeExpiration,
                protectiveOptionRollToDaysToExp: s.strategySettings.protectiveOptionRollToDaysToExp,
                protectiveOptionOverrideRollToDaysToExp: s.strategySettings.protectiveOptionOverrideRollToDaysToExp,
                protectiveOptionEvergreenOverrideRollToDaysToExp: s.strategySettings.protectiveOptionEvergreenOverrideRollToDaysToExp,
                protectiveOptionRollToXBusinessDaysToExp: s.strategySettings.protectiveOptionRollToXBusinessDaysToExp,

                // 2nd protective option
                secondProtectiveOptionOffset: s.strategySettings.secondProtectiveOptionOffset,
                secondProtectiveOptionRollXDaysBeforeExpiration: s.strategySettings.secondProtectiveOptionRollXDaysBeforeExpiration,
                secondProtectiveOptionRollToDaysToExp: s.strategySettings.secondProtectiveOptionRollToDaysToExp,
                secondProtectiveOptionOverrideRollToDaysToExp: s.strategySettings.secondProtectiveOptionOverrideRollToDaysToExp,
                secondProtectiveOptionEvergreenOverrideRollToDaysToExp: s.strategySettings.secondProtectiveOptionEvergreenOverrideRollToDaysToExp,
                secondProtectiveOptionRollToXBusinessDaysToExp: s.strategySettings.secondProtectiveOptionRollToXBusinessDaysToExp,

                //
                customDates: strategySettings.expirationSettings.customDates.map(x => x.value),
                joinRollBuffer: strategySettings.expirationSettings.joinRollBuffer,
                expirationsToLookForward: strategySettings.expirationSettings.expirationsToLookForward,
                expirationsToLookForwardAbove: strategySettings.expirationSettings.expirationsToLookForward,
                expirationsToLookForwardDown: strategySettings.expirationSettings.expirationsToLookForward,

                //
                expirationSmartModeSettings: this._applicationSettingsService.adjustmentPricingGrid.expirationSmartMode,
                useMarginEfficientAdjustment: this._applicationSettingsService.adjustmentPricingGrid.useMarginEfficientAdjustment,
                adjustToQty: strategySettings.globalSettings.adjustToQty,

                //
                overrideAtm: strategySettings.globalSettings.overrideAtm,

                //
                useCharlesSchwabFix: this._applicationSettingsService.adjustmentPricingGrid.useCharlesSchwabFix
            };

            return dto;
        });

        if (adjustmentPricingSettings.length !== strategySettings.settings.length) {
            result.errors.push('Strategies count does not match, unknown error');
            return result;
        }


        result.settings = adjustmentPricingSettings;
        return result;
    }


    // noinspection JSUnusedGlobalSymbols
    onSweetPriceSettingsRequest(x: SweetPricePopupConfig): void {
        const tpl = this.settingsSection.globalSettings.selectedTemplate;
        if (isVoid(tpl)) {
            throw Error('Template not selected');
        }
        x.daysToLookForward = tpl.expirationSettings.expirationsToLookForward;
        x.userId = this.userContext;
        x.portfolioId = this.selectedPortfolio.id;
        x.template = tpl;
    }


    @DetectMethodChanges({isAsync: true})
    async onSweetPriceSyncRequest(): Promise<void> {
        const result = await this.syncTrackingDialog.show(
            this.userContext,
            this.selectedPortfolio.id,
            this.positionsSection.positionsData.map(x => x.positions.map(y => y.asDto())),
            this.settingsSection.globalSettings.selectedTemplate
        );

        if (result === 'Current') {

            try {

                await this.onStartTrackingRequest();
                this._toastr.info('Sweet Price Tracking was updated to track new position configuration');

            } catch (e) {

                //
                this._toastr.error('"Update Sweet Price Tracking" operation completed with errors');

            }

        } else {

            //TODO:

        }
    }


    // noinspection JSUnusedGlobalSymbols
    sweetPriceIsSync(): boolean {

        const userId = this.userContext;

        const portfolioId = this.selectedPortfolio.id;

        const positions = this.positionsSection.positionsData.map(x => x.positions.map(y => y.asDto()));

        const templateId = this.settingsSection.globalSettings.selectedTemplate
            ? this.settingsSection.globalSettings.selectedTemplate.templateId
            : null;

        return this._sweetPriceTrackingService.isSync(
            userId,
            portfolioId,
            positions,
            templateId
        );
    }


    // noinspection JSUnusedGlobalSymbols
    sweetPriceIsTracking(): boolean {
        const userId = this.userContext;
        const portfolioId = this.selectedPortfolio.id;
        return this._sweetPriceTrackingService.isTracking(userId, portfolioId);
    }


    // noinspection JSUnusedGlobalSymbols
    getContextUserId(): string {
        return this.userContext;
    }


    // noinspection JSUnusedGlobalSymbols
    async onTemplateUpdated(tpl: ICashFlowAdjustmentSettingsTemplate): Promise<void> {

        if (isVoid((tpl))) {
            this._toastr.error('"Update Template" operation completed with errors');
            console.error('template is null');
            return;
        }

        this.isDataLoading = true;

        try {
            const cmd = new UpdateTrackingObjectsOnTemplateUpdate(
                this.userContext,
                tpl
            );
            await this._shellClient.processCommand(cmd);
        } catch {
            this.isDataLoading = false;
        }
    }


    // noinspection JSUnusedGlobalSymbols
    async onSweetPriceSettingsUpdated(): Promise<void> {

        const settings = this._sweetPriceTrackingService.getSweetPriceSettings(
            this.userContext,
            this.selectedPortfolio.id
        );

        try {
            this._sweetPriceTrackingService.validateSweetPriceSettings(
                settings,
                {
                    strategy: this.settingsSection.globalSettings.selectedStrategy,
                    underlying: this.settingsSection.globalSettings.underlying,
                    priceZoneAtmNotificationFilterEnabled: false
                },
            );
        } catch (e) {
            this._toastr.error(e);
            return;
        }

        this.isDataLoading = true;

        try {

            const cmd = new UpdateTrackingObjectOnSweetPriceSettingsUpdate(
                this.userContext,
                this.selectedPortfolio,
                settings
            );

            await this._shellClient.processCommand(cmd);
        } catch {
            this.isDataLoading = false;
        }
    }

    // noinspection JSUnusedGlobalSymbols
    @DetectMethodChanges()
    onTemplateApplied() {

        // This is part of the copy/paste settings functionality
        // when pasting to an empty panel. It is split into 2 steps:
        //  1. Applying default portfolio and template
        //  2. After template applied, the object with the following key has to be in clipboard
        // If no such key means that this call is not part of copy/paste
        const templateSettings = this._clipboardService.get('apg.copy/paste.settings.temp');

        if (!isVoid(templateSettings)) {
            this.settingsSection.applyPasteTemplate(templateSettings);
        }

        //

        this.positionsSection.syncPositionsAndSettingsRegardingSecondOptions();

    }


    // noinspection JSUnusedGlobalSymbols
    getSecondOptionsConfiguration() {

        const config = this.positionsSection.positionsData.map(pd => {

            const hasSecondSpread = pd.positions.some(x => x.role.startsWith('SecondSpread'));

            const hasSecondProtectiveOption = pd.positions.some(x => x.role.startsWith('SecondProtective'));

            return {
                hasSecondSpread,
                hasSecondProtectiveOption
            };
        });

        return config;
    }


    // noinspection JSUnusedGlobalSymbols
    isDynamicOffsetEnabled(): boolean {
        return this.settingsSection.globalSettings.isDynamicOffsets;
    }


    // noinspection JSUnusedGlobalSymbols
    getSelectedPortfolio(): ApgPortfolio {
        return this.selectedPortfolio;
    }


    // noinspection JSUnusedGlobalSymbols
    getSelectedTemplate(): ICashFlowAdjustmentSettingsTemplate {
        return this.settingsSection.globalSettings.selectedTemplate;
    }


    // noinspection JSUnusedGlobalSymbols
    getDefaultTemplate(underlying: string, strategy: CashFlowStrategy): ICashFlowAdjustmentSettingsTemplate {
        return this._cashFlowStrategyTemplatesService
            .getOrCreateDefaultTemplate(underlying, strategy);
    }


    // noinspection JSUnusedGlobalSymbols
    isOperationInProgress(): boolean {
        return this._longRunningOperationClients > 0;
    }


    async pastePositions(positionsData: PositionsData[]): Promise<void> {

        const underlying = positionsData[0].positions[0].underlyingSymbol;

        let strategy: CashFlowStrategy = positionsData.length === 2
            ? 'Calls & Puts'
            : positionsData[0].positions[0].strategy;

        const portfolio = this.selectedPortfolio;

        if (!isVoid(portfolio)) {
            if (portfolio.id !== DefaultApgPortfolioId) {
                portfolio.asset = underlying;
                portfolio.strategy = strategy;
                const currentUserPortfolios = this.getCurrentUserPortfolios();
                this._portfoliosService.savePortfolios(currentUserPortfolios);
            }
        }

        this._savedPositionsService.save(
            isVoid(portfolio) ? DefaultApgPortfolioId : portfolio.id,
            underlying,
            strategy,
            positionsData
        );

        if (isVoid(portfolio) || portfolio.id === DefaultApgPortfolioId) {
            await this.setDefaultPortfolio(underlying, strategy);
        } else {
            this._selectedPortfolio = portfolio;
            await this.onPortfolioSelected({value: portfolio, event: 'ets.override'})
        }
    }


    async pasteSettings(pasteSettings: ICashFlowAdjustmentSettingsTemplate,
                        isPartOfCombo?: boolean): Promise<void> {


        // this should be picked up later in 'onTemplatedApplied()' method
        this._clipboardService.put('apg.copy/paste.settings.temp', pasteSettings);

        if (!isPartOfCombo) {
            await this.setDefaultPortfolio(
                pasteSettings.underlying,
                pasteSettings.strategyName
            );
        }
    }


    // noinspection JSUnusedGlobalSymbols
    async copyPositionsAndSettings(): Promise<void> {
        if (!this.selectedPortfolio) {
            return;
        }

        const positionsData = this.positionsSection.positionsData;

        if (isVoid(positionsData)) {
            this._toastr.error('No positions found');
            return;
        }

        const positionsDtos = positionsData.map(pd => pd.positions.map(p => p.asDto()));

        const settings = this.settingsSection.getSettings();

        const obj = {
            underlying: settings.underlying,
            strategy: settings.strategyName,
            positions: positionsDtos,
            settings
        };

        const json = JSON.stringify(obj);

        const key = 'apg.copy/paste.combination';

        const data = key + json;

        await navigator.clipboard.writeText(data);

    }


    // noinspection JSUnusedGlobalSymbols
    async pastePositionsAndSettings(): Promise<void> {

        const clipboardData = await navigator.clipboard.readText();

        if (!clipboardData.startsWith('apg.copy/paste.combination')) {
            this._toastr.error('Cannot paste positions and settings: clipboard contains corrupted data');
            return Promise.resolve();
        }

        const positionsAndSettingsJson = clipboardData.split('apg.copy/paste.combination')[1];

        const parsedObject = JSON.parse(positionsAndSettingsJson) as {
            underlying: string,
            strategy: CashFlowStrategy
            positions: BeforePositionDto[][],
            settings: ICashFlowAdjustmentSettingsTemplate
        };

        //
        // Prepare positions
        //
        const positionDtos = parsedObject.positions;

        if (isVoid(positionDtos)) {
            this._toastr.error('Positions Data corrupted, cannot proceed');
            console.error(positionDtos);
            return;
        }

        const chain = await this._positionsStateService.getOptionChain(parsedObject.underlying);

        if (isVoid(chain)) {
            this._toastr.error(`Failed to retrieve option chain for ${parsedObject.underlying}`);
            return;
        }

        const models = positionDtos.map(positions => {
            const model = this._positionsStateService
                .makeBeforePositionModelFromDto(positions, chain);
            return model;
        });

        const positionsData = models.map(m => {
            return {
                positions: m,
                strategy: m[0].strategy,
                isSavedState: true
            } as PositionsData;
        });

        //
        // Prepare Settings
        //
        const pasteSettings = parsedObject.settings;

        if (isVoid(pasteSettings)) {
            this._toastr.error('Settings data is corrupted');
            console.error(pasteSettings);
            return;
        }

        await this.onLongRunningOperationChanged(true);

        try {
            await this._cashFlowStrategyTemplatesService
                .clearLastUsedTemplate(DefaultApgPortfolioId);

            await this.pasteSettings(pasteSettings, true);

            await this.pastePositions(positionsData);

        } finally {

            await this.onLongRunningOperationChanged(false);

        }
    }


    getPositions(): PositionsData[] {
        return this.positionsSection.positionsData;
    }


    private async onDataLoaded(reply: GetAdjustmentSolutionsReply) {
        try {

            if (isVoid(reply)) {
                return;
            }

            if (isVoid(reply.requestId)) {
                return;
            }

            const ix = this._pendingRequestId.indexOf(reply.requestId);

            if (ix < 0) {
                return;
            }

            this._pendingRequestId.splice(ix, 1);

            if (reply.error) {
                this._toastr.error('"Calculate Adjustments" operation completed with errors');
                return;
            }

            if (reply.adjustments.length === 0) {
                this._toastr.error('Service returned no result');
                return;
            }

            await this.onDataLoadedInternally(reply);

        } finally {
            if (this._pendingRequestId.length === 0) {
                this.isDataLoading = false;
            }
        }
    }

    private async onDataLoadedInternally(reply: GetAdjustmentSolutionsReply) {

        const isZonesGridData = reply.requestId.startsWith('zones');

        const chain = await this._optionChainsService.getChain(this.settingsSection.underlying);

        const expirationDates = chain.expirations
            .map(x => x.dateWithDaysToExpiration.split(' '))
            .filter(x => x.length == 3)
            .map(x => x[1] + ' ' + x[2]);

        reply.adjustments.forEach(adj => {

            const dateWithExp = expirationDates.find(x => x.startsWith(adj.expirationPretty));

            if (!isVoid(dateWithExp)) {
                adj.expirationDatePrettyWithDaysToExpiration = dateWithExp;
            }

            this.setRatios(adj);

        });

        if (isZonesGridData) {
            this._quoteIndex.addDataZones(reply.adjustments);
            this._strategyCodeIndex.addDataZones(reply.adjustments);
        } else {
            this._quoteIndex.addData(reply.adjustments);
            this._strategyCodeIndex.addData(reply.adjustments)
        }

        if (isZonesGridData) {
            if (this.zonesGridSection) {
                this.zonesGridSection.setData(reply.adjustments)
            }
        } else {
            this.extractExtras(reply.adjustments);
            this.strategiesSection.setData(reply.adjustments);
            this.priceboxSection.setData(reply.adjustments);
            this.positionsSection.updateAtmDistance();
        }

        const theoAdjustment = reply.adjustments
            .find(adj => !isVoid(adj.optionStrategyCode) && adj.optionStrategyCode.indexOf(' !') > 0);

        if (!isVoid(theoAdjustment)) {

            if (isZonesGridData) {
                this._zonesGridSectionHeaderVolatility = '(T)';
            } else {
                this._priceboxSectionHeaderVolatility = '(T)';
            }

            const parts = theoAdjustment.mainLegs[0].ticker.split(' ');
            const volPart = parts.find(p => p.startsWith('!'));

            if (!isVoid(volPart)) {
                const volString = volPart.substring(1);
                const number = parseFloat(volString);
                if (isValidNumber(number, true)) {

                    if (isZonesGridData) {
                        this._zonesGridSectionHeaderVolatility = `(T)`;
                    } else {
                        this._priceboxSectionHeaderVolatility = `(T)`;
                    }
                }
            }
        } else {
            if (isZonesGridData) {
                this._zonesGridSectionHeaderVolatility = null;
            } else {
                this._priceboxSectionHeaderVolatility = null;
            }
        }


        // in case of missing quotes, fill out from cache
        setTimeout(() => {
            if (!this.settingsSection.globalSettings.useTheoreticalPrices) {
                // we avoid populating strategy prices from cache if its theo prices
                // because IV probably has changed, and previous values don't make sense
                const strategyPrices = this._lastQuoteCache.getAllLastStrategyPrices();
                this.onStrategyPrice(strategyPrices);
            }

            const allQuotes = this._lastQuoteCache.getAllQuotes();
            this.onQuote(allQuotes);
        }, 50);

        if (this.settingsSection.globalSettings.useTheoreticalPrices) {
            if (this.settingsSection.globalSettings.theoreticalPriceMode === 'Fill Blanks') {
                if (isZonesGridData) {
                    if (this.settingsSection.globalSettings.theoreticalPriceTarget !== 'Price Box') {
                        this._toastr.info('"Fill Blanks" mode is selected for "Zones Grid". ' +
                            'Blank adjustments examination will start in 10 seconds',
                            'Theoretical Prices - "Fill Blanks"',
                            {timeOut: 10 * 1000});
                    }
                } else {
                    if (this.settingsSection.globalSettings.theoreticalPriceTarget !== 'Zones Grid') {
                        this._toastr.info('"Fill Blanks" mode is selected for "Price Box". ' +
                            'Blank adjustments examination will start in 10 seconds',
                            'Theoretical Prices - "Fill Blanks"',
                            {timeOut: 10 * 1000});
                    }
                }
                let target = this.settingsSection.globalSettings.theoreticalPriceTarget;

                setTimeout(() => this
                    .fillTheBlanks(reply.adjustments, isZonesGridData), 10 * 1000);
            }
        }
    }

    private onTrackingStartedConfirmation(payload: TrackingStartedMessage) {

        this._sweetPriceTrackingService.onTrackingStartedConfirmation(payload);

        if (isVoid(this.selectedPortfolio)) {
            return;
        }

        const key = this.userContext + this.selectedPortfolio.id;

        if (payload.id !== key) {
            return;
        }

        this.isDataLoading = false;
    }

    private onTrackingStoppedConfirmation(payload: TrackingStoppedMessage) {

        this._sweetPriceTrackingService.onTrackingStoppedConfirmation(payload);

        if (isVoid(this.selectedPortfolio)) {
            return;
        }

        const key = this.userContext + this.selectedPortfolio.id;

        if (payload.id !== key) {
            return;
        }

        this.isDataLoading = false;
    }

    private onTrackingRequestError(payload: TrackingRequestErrorMessage) {

        if (isVoid(this.selectedPortfolio)) {
            return;
        }

        if (isVoid(this.settingsSection.globalSettings.selectedTemplate)) {
            return;
        }

        if (payload.origin === 'portfolio') {
            const key = this.userContext + this.selectedPortfolio.id;

            if (payload.requestId !== key) {
                return;
            }

            this.isDataLoading = false;

            this._toastr.error(payload.message);

        } else if (payload.origin === 'template') {

            const key = this.settingsSection.globalSettings.selectedTemplate.templateId;

            if (payload.requestId !== key) {
                return;
            }

            this.isDataLoading = false;

            this._toastr.error(payload.message);
        }
    }

    private onTrackingTemplateUpdated(payload: TrackingTemplateUpdated) {
        this.isDataLoading = false;

        if (isVoid(payload.message)) {
            return;
        }

        this._toastr.success(payload.message);
    }

    private onTrackingNotificationSettingsUpdated(payload: TrackingNotificationSettingsUpdated) {
        this.isDataLoading = false;

        if (isVoid(payload.message)) {
            return;
        }
        this._toastr.success(payload.message);
    }

    showPriceBoxMenu() {
        this.priceBoxMenu.show();
    }

    showZonesGriMenu() {
        this.zonesGridMenu.show();
    }

    private setRatios(adj: CashFlowAdjustment) {
        adj.perContract = 1;
        adj.ratio = 1;

        if (adj.isEmpty) {
            return;
        }

        const a = adj.beforeState.find(x => x.role === 'ShortOption');
        const b = adj.afterState.find(x => x.role === 'ShortOption');


        if (isVoid(a) || isVoid(b)) {
            return;
        }

        if (isVoid(a.ticker) || isVoid(b.ticker)) {
            return;
        }

        if (b.qty === a.qty) {
            return;
        }

        adj.perContract = Math.max(Math.abs(b.qty), Math.abs(a.qty));

        const qtties = adj.mainLegs
            .concat(adj.linkedLegs)
            .concat(adj.secondLinkedLegs)
            .concat(adj.thirdLinkedLegs)
            .map(x => x.qty);

        const ratio = findHCF(qtties);

        adj.ratio = ratio;
    }

    private extractExtras(pricings: CashFlowAdjustment[]) {

        pricings.forEach(pricing => {

            if (pricing.isPriceToOpen) {
                return;
            }

            try {

                const mappings = pricing.afterState.map((el, i) => ({
                    role: el.role,
                    pair: [el, pricing.beforeState[i]]
                }));

                const so = mappings.find(x => x.role === 'ShortOption');

                if (isVoid(so)) {
                    return;
                }

                const diff = Math.abs(so.pair[0].qty) - Math.abs(so.pair[1].qty);

                if (diff === 0) {
                    return;
                }

                const isIncrease = diff > 0;

                const extraPositions = mappings.map(m => {

                    const afterPosition = m.pair[isIncrease ? 0 : 1];
                    const beforePosition = m.pair[isIncrease ? 1 : 0]

                    const ticker = afterPosition.ticker;
                    const qty = afterPosition.qty - beforePosition.qty;
                    let role = afterPosition.role;
                    let expiration = afterPosition.expiration;
                    let price = null;
                    let side = isIncrease ? afterPosition.side : beforePosition.side;
                    let strategy = afterPosition.strategy;
                    let underlying = afterPosition.underlying;
                    let type = afterPosition.type;
                    let expirationFull = afterPosition.expirationFull;
                    let strike = afterPosition.strike;

                    const pos: SolutionPositionDto = {
                        ticker,
                        qty,
                        role,
                        expiration,
                        price,
                        side,
                        strategy,
                        underlying,
                        type,
                        expirationFull,
                        strike
                    };

                    return pos;
                });

                pricing.extraPositions = extraPositions;
                pricing.extraType = isIncrease ? 'increase' : 'decrease';

                this._quoteIndex.addToQuoteIndex(extraPositions, isIncrease);

            } catch {
                //
            }
        });
    }

    getAdjustmentPrice(x: CashFlowAdjustment): number {
        const px = (!isValidNumber(x.price) ? NaN : (x.price / x.perContract)) * x.ratio;
        return px;
    }

    private async generateAdjustmentOrders(args: { memento: DefaultsMemento, generateOrders?: boolean }) {

        if (!args.generateOrders) {

            const promises = this.positionsSection.positionsData.map(async (x, ix) => {
                const mm = ix === 0 ? args.memento.first : args.memento.second;
                const afterState = await this.calculateAfterState(x.positions, mm);
                return afterState;
            });

            const resolved = await Promise.all(promises);

            this.positionsSection.applyPositionsAfterStateAfterDefaultsUpdate(resolved);

            return;
        }

        const beforeState = this.positionsSection.positionsData;

        const promises = beforeState.map(async (pd, ix) => {

            const mm = ix == 0 ? args.memento.first : args.memento.second;
            const cfa = await this.makeCashflowAdjustmentObject(pd, mm);
            return cfa;

        });

        const promiseResult = await Promise.all(promises);

        const pricings = promiseResult.filter(x => !isVoid(x));

        if (pricings.length === 0) {
            this._toastr.info("There was no difference between " +
                "before and after state, hence no orders were generated")
            return;
        }


        let strategyType: 'single' | 'double' = pricings.length == 2 ? 'double' : 'single';

        this.solutionPopupCmp.show(
            this.selectedPortfolio,
            pricings,
            strategyType,
            undefined
        );
    }

    private async makeCashflowAdjustmentObject(x: PositionsData, memento: DefaultSettingsMemento): Promise<CashFlowAdjustment> {

        const beforeState = x.positions.map(x => {
            const p: SolutionPositionDto = {
                ticker: x.getTicker(),
                side: x.side,
                qty: x.qty,
                underlying: x.underlyingSymbol,
                type: x.type,
                strike: x.strike,
                expiration: makeGuiFriendlyExpirationDate(x.selectedExpiration.optionExpirationDate),
                role: x.role,
                strategy: x.strategy,
                priceMultiplier: Math.abs(x.qty) * -1,
                expirationFull: makeFullExpirationDate(x.selectedExpiration.optionExpirationDate)
            };

            return p;
        });
        const afterState = await this.calculateAfterState(x.positions, memento);

        this.markChangedPositions(beforeState, afterState);

        await this._afterStateDetalizationService.add(afterState, x.strategy);

        const orders = this.makeOrdersForPositionAdjustment(x.positions, afterState);

        if (orders.length === 0) {
            return null;
        }

        const mainLegs = [];
        const linkedLegs = [];
        const secondLinkedLegs = [];

        orders.forEach((o, ix) => {
            if (ix < 4) {
                mainLegs.push(o);
            } else if (ix < 8) {
                linkedLegs.push(o);
            } else {
                secondLinkedLegs.push(o);
            }
        });

        const shortOption = x.positions.find(x => x.role === 'ShortOption');

        this._quoteIndex.addToQuoteIndex(beforeState, false);
        this._quoteIndex.addToQuoteIndex(afterState, true);
        this._quoteIndex.addToQuoteIndex(orders, false);

        const optionStrategyCode = orders.map(x => `${x.qty}|${x.ticker}`).join(';');

        this._lastQuoteCache.subscribeStrategyCodesDiff([], [optionStrategyCode]);

        const dayOfWeek = DateTime.fromFormat(shortOption.selectedExpiration.optionExpirationDate
            , 'yyyy-MM-dd').weekdayShort;

        balanceStatePositions(beforeState, afterState);

        const obj: CashFlowAdjustment = {
            adjustmentTypeObj: undefined,
            logicalIdWithStrategy: "",
            uniqueAdjustmentSpecificationId: getShortUUID(),
            cashFlowContext: "N/A",
            cashFlowStrategy: x.strategy,
            underlying: x.positions[0].underlyingSymbol,
            logicalId: '#0',
            initialStrike: shortOption.strike + '',
            destinationStrike: shortOption.strike + '',
            direction: 'Sideways',
            adjustmentType: 'CUSTOM',
            isEmpty: false,
            optionStrategyCode: optionStrategyCode,
            expiration: shortOption.selectedExpiration.optionExpirationDate,
            expirationPretty: makeGuiFriendlyExpirationDate(shortOption.selectedExpiration.optionExpirationDate),
            mainLegs: mainLegs,
            linkedLegs: linkedLegs,
            secondLinkedLegs: secondLinkedLegs,
            thirdLinkedLegs: [],
            beforeState: beforeState,
            afterState: afterState,
            price: null,
            marketDelta: null,
            isDefault: false,
            description: "Sideways",
            atmStrike: shortOption.strike,
            coupleTag: "99+99",
            dayOfWeek: dayOfWeek,
            templateName: this.getSelectedTemplate().templateName,
            // "templateId": this.getSelectedTemplate().templateId,
            dynamicOffsets: false,
            actualSpreadOffset: null,
            actualSpreadWidth: null,
            actualSecondSpreadWidth: null,
            actualProtectiveOptionOffset: null,
            actualSecondProtectiveOptionOffset: null,
            actualSecondSpreadOffset: null,
            isPriceToOpen: false,
            expirationDatePrettyWithDaysToExpiration: shortOption.selectedExpiration.dateWithDaysToExpiration,
            perContract: null,
            ratio: null,
            extraPositions: [],
            extraType: null,
            positionsVisible: true,
            isPriceToClose: false
        };

        return obj;
    }

    private async calculateAfterState(beforeState: BeforePositionModel[], memento: DefaultSettingsMemento)
        : Promise<SolutionPositionDto[]> {

        const result = [];

        const desiredState: Record<CashFlowStrategyRole, DesiredMementoState> = {} as any;

        const defaults = this._positionsStateService.getDefaultsProvider(
            this.selectedPortfolio.id,
            this.settingsSection.globalSettings.underlying,
            'double'
        );

        // Short Option
        const shortOption = beforeState.find(x => x.role === 'ShortOption');

        const baseQtyChanged = Math.abs(shortOption.qty) !== Math.abs(memento.orderQty);

        if (baseQtyChanged) {

            desiredState['ShortOption'] = {
                qty: memento.orderQty * -1,
                strike: shortOption.strike,
                expiration: shortOption.selectedExpiration.optionExpirationDate
            };
        }

        // Spread Long Leg
        const spreadLong = beforeState.find(x => x.role === 'SpreadLongLeg');

        if (baseQtyChanged) {
            desiredState['SpreadLongLeg'] = {
                qty: memento.orderQty,
                strike: spreadLong.strike,
                expiration: spreadLong.selectedExpiration.optionExpirationDate
            };
        }

        // Spread Short Leg
        const spreadShort = beforeState.find(x => x.role === 'SpreadShortLeg');

        if (baseQtyChanged) {
            desiredState['SpreadShortLeg'] = {
                qty: memento.orderQty * -1,
                strike: spreadShort.strike,
                expiration: spreadShort.selectedExpiration.optionExpirationDate
            };
        }

        // 2nd Spread Long Leg
        let secondSpreadLongLeg = beforeState.find(x => x.role === 'SecondSpreadLongLeg');
        let secondSpreadShortLeg = beforeState.find(x => x.role === 'SecondSpreadShortLeg');

        let secondSpreadLongLegNew: SolutionPositionDto;
        let secondSpreadShortLegNew: SolutionPositionDto;

        if (!isVoid(secondSpreadLongLeg)) {
            if (memento.hasSecondSpread) {
                desiredState['SecondSpreadLongLeg'] = {
                    qty: memento.orderQty,
                    strike: secondSpreadLongLeg.strike,
                    expiration: secondSpreadLongLeg.selectedExpiration.optionExpirationDate
                };
                desiredState['SecondSpreadShortLeg'] = {
                    qty: memento.orderQty,
                    strike: secondSpreadShortLeg.strike,
                    expiration: secondSpreadShortLeg.selectedExpiration.optionExpirationDate
                };
            } else {
                secondSpreadLongLeg = undefined;
                secondSpreadShortLeg = undefined;

            }
        } else {

            if (memento.hasSecondSpread) {

                const defaults = this._positionsStateService.getDefaultsProvider(
                    this.selectedPortfolio.id,
                    this.settingsSection.globalSettings.underlying,
                    'double'
                );

                const chain = await this._optionChainsService
                    .getChain(this.settingsSection.globalSettings.underlying);

                const exp = chain.expirations
                    .find(x => x.daysToExpiration >= defaults.first.secondSpreadDaysToExpiration);

                const multiplier = 1;

                let longLegStrike = isReversedCashFlowOrder(spreadShort.strategy)
                    ? spreadShort.strike + (defaults.first.secondSpreadOffset || 0) * multiplier
                    : spreadShort.strike - (defaults.first.secondSpreadOffset || 0) * multiplier;

                let shortLegStrike = isReversedCashFlowOrder(spreadShort.strategy)
                    ? longLegStrike + (defaults.first.secondSpreadWidth || 0) * multiplier
                    : longLegStrike - (defaults.first.secondSpreadWidth || 0) * multiplier;

                if (exp.strikes.indexOf(longLegStrike) < 0) {
                    longLegStrike = null;
                }

                const longLegTicker = makeOptionTicker(exp, spreadShort.type, longLegStrike, 'American');

                const longLegPosition: SolutionPositionDto = {
                    ticker: longLegTicker,
                    side: 'Long',
                    qty: memento.orderQty,
                    underlying: spreadLong.underlyingSymbol,
                    type: spreadLong.type,
                    strike: longLegStrike,
                    expiration: makeGuiFriendlyExpirationDate(exp.optionExpirationDate),
                    role: 'SecondSpreadLongLeg',
                    strategy: spreadLong.strategy,
                    priceMultiplier: Math.sign(spreadLong.qty) * -1,
                    expirationFull: makeFullExpirationDate(exp.optionExpirationDate)
                };

                secondSpreadLongLegNew = longLegPosition;

                const shortLegTicker = makeOptionTicker(exp, spreadShort.type, shortLegStrike, 'American');

                const shortLegPosition: SolutionPositionDto = {
                    ticker: shortLegTicker,
                    side: 'Short',
                    qty: memento.orderQty * -1,
                    underlying: spreadShort.underlyingSymbol,
                    type: spreadShort.type,
                    strike: shortLegStrike,
                    expiration: makeGuiFriendlyExpirationDate(exp.optionExpirationDate),
                    role: 'SecondSpreadShortLeg',
                    strategy: spreadShort.strategy,
                    priceMultiplier: Math.sign(spreadShort.qty) * -1,
                    expirationFull: makeFullExpirationDate(exp.optionExpirationDate)
                };

                secondSpreadShortLegNew = shortLegPosition;
            }
        }


        // Protective Option Leg
        const poLeg = beforeState.find(x => x.role === 'ProtectiveOption');

        let poLegStrike: number;

        if (!isVoid(secondSpreadShortLegNew)) {
            poLegStrike = secondSpreadShortLegNew.strike
        } else {
            const hadSecondSpread = beforeState.some(x => x.role === 'SecondSpreadShortLeg');
            if (isVoid(secondSpreadShortLeg) && hadSecondSpread) {
                poLegStrike = spreadShort.strike;
            } else {
                poLegStrike = poLeg.strike;
            }
        }

        if (memento.protectiveOptionOverrideQty) {

            desiredState['ProtectiveOption'] = {
                qty: memento.protectiveOptionOverrideQty,
                strike: poLegStrike,
                expiration: poLeg.selectedExpiration.optionExpirationDate
            };

        } else {
            desiredState['ProtectiveOption'] = {
                qty: memento.orderQty,
                strike: poLegStrike,
                expiration: poLeg.selectedExpiration.optionExpirationDate
            };
        }

        let secondPoLeg = beforeState.find(x => x.role === 'SecondProtectiveOption');

        let secondPoLegNew: SolutionPositionDto;

        if (!isVoid(secondPoLeg)) {

            let secondPoLegStrike: number;

            const hadSecondSpread = beforeState.some(x => x.role === 'SecondSpreadShortLeg');

            if (isVoid(secondSpreadShortLegNew) && !hadSecondSpread) {
                secondPoLegStrike = secondPoLeg.strike;
            } else {
                secondPoLegStrike = isReversedCashFlowOrder(secondPoLeg.strategy)
                    ? poLegStrike + (defaults.first.secondProtectiveOptionOffset || 0)
                    : poLegStrike - (defaults.first.secondProtectiveOptionOffset || 0);
            }

            if (memento.hasSecondPo) {
                if (memento.secondProtectiveOptionOverrideQty) {
                    desiredState['SecondProtectiveOption'] = {
                        qty: memento.secondProtectiveOptionOverrideQty,
                        strike: secondPoLegStrike,
                        expiration: secondPoLeg.selectedExpiration.optionExpirationDate
                    };
                } else {
                    desiredState['SecondProtectiveOption'] = {
                        qty: memento.orderQty,
                        strike: secondPoLegStrike,
                        expiration: secondPoLeg.selectedExpiration.optionExpirationDate
                    };
                }
            } else {
                secondPoLeg = undefined;
            }
        } else {

            if (memento.hasSecondPo) {

                const chain = await this._optionChainsService
                    .getChain(this.settingsSection.globalSettings.underlying);

                const exp = chain.expirations
                    .find(x => x.daysToExpiration >= defaults.first.secondProtectiveOptionDaysToExpiration);

                const multiplier = 1;

                let strike = isReversedCashFlowOrder(poLeg.strategy)
                    ? poLegStrike + (defaults.first.secondProtectiveOptionOffset || 0) * multiplier
                    : poLegStrike - (defaults.first.secondProtectiveOptionOffset || 0) * multiplier;

                if (exp.strikes.indexOf(strike) < 0) {
                    strike = null;
                }

                const ticker = makeOptionTicker(exp, poLeg.type, strike, 'American');

                const p: SolutionPositionDto = {
                    ticker: ticker,
                    side: poLeg.side,
                    qty: (memento.secondProtectiveOptionOverrideQty || memento.orderQty) * Math.sign(poLeg.qty),
                    underlying: poLeg.underlyingSymbol,
                    type: poLeg.type,
                    strike: strike,
                    expiration: makeGuiFriendlyExpirationDate(exp.optionExpirationDate),
                    role: 'SecondProtectiveOption',
                    strategy: poLeg.strategy,
                    priceMultiplier: Math.sign(poLeg.qty) * -1,
                    expirationFull: makeFullExpirationDate(exp.optionExpirationDate)
                };

                secondPoLegNew = p;
            }
        }

        let overrideState = desiredState['ShortOption'];
        let afterLeg = this.makeAfterLeg(shortOption, overrideState);
        result.push(afterLeg);

        overrideState = desiredState['SpreadLongLeg'];
        afterLeg = this.makeAfterLeg(spreadLong, overrideState);
        result.push(afterLeg);

        overrideState = desiredState['SpreadShortLeg'];
        afterLeg = this.makeAfterLeg(spreadShort, overrideState);
        result.push(afterLeg);

        if (secondSpreadLongLeg) {
            overrideState = desiredState['SecondSpreadLongLeg'];
            afterLeg = this.makeAfterLeg(secondSpreadLongLeg, overrideState);
            result.push(afterLeg);

            overrideState = desiredState['SecondSpreadShortLeg'];
            afterLeg = this.makeAfterLeg(secondSpreadShortLeg, overrideState);
            result.push(afterLeg);

        } else {

            if (!isVoid(secondSpreadLongLegNew) && !isVoid(secondSpreadShortLegNew)) {
                result.push(secondSpreadLongLegNew);
                result.push(secondSpreadShortLegNew);
            }

        }

        overrideState = desiredState['ProtectiveOption'];
        afterLeg = this.makeAfterLeg(poLeg, overrideState);
        result.push(afterLeg);

        if (secondPoLeg) {
            overrideState = desiredState['SecondProtectiveOption'];
            afterLeg = this.makeAfterLeg(secondPoLeg, overrideState);
            result.push(afterLeg);
        } else {
            if (secondPoLegNew) {
                result.push(secondPoLegNew);
            }
        }

        if (isReversedCashFlowOrder(shortOption.strategy)) {
            result.reverse();
        }

        return result;
    }

    private makeAfterLeg(leg: BeforePositionModel, override: DesiredMementoState): SolutionPositionDto {

        const expiration = isVoid(override)
            ? makeGuiFriendlyExpirationDate(leg.selectedExpiration.optionExpirationDate)
            : makeGuiFriendlyExpirationDate(override.expiration);

        const expirationFull = makeFullExpirationDate(expiration);

        const qty = isVoid(override)
            ? leg.qty
            : override.qty;

        const strike = isVoid(override)
            ? leg.strike
            : override.strike;

        const p: SolutionPositionDto = {
            ticker: leg.getTicker(),
            side: leg.side,
            qty: qty,
            underlying: leg.underlyingSymbol,
            type: leg.type,
            strike: strike,
            expiration: expiration,
            role: leg.role,
            strategy: leg.strategy,
            priceMultiplier: Math.sign(leg.qty) * -1,
            expirationFull: expirationFull
        };

        return p;
    }

    private makeOrdersForPositionAdjustment(beforeState: BeforePositionModel[], afterState: SolutionPositionDto[]): SolutionOrderLegDto[] {

        const roles: CashFlowStrategyRole[] = [
            'ShortOption',
            'SpreadLongLeg',
            'SpreadShortLeg',
            'SecondSpreadLongLeg',
            'SecondSpreadShortLeg',
            'ProtectiveOption',
            'SecondProtectiveOption'
        ];

        const pairs = roles.map(role => {
            const before = beforeState.find(x => x.role === role);
            const after = afterState.find(x => x.role === role);

            return {before, after};
        });


        const orders = pairs.flatMap(pair => {

            if (isVoid(pair.before) && isVoid(pair.after)) {
                return undefined;
            }

            if (isVoid(pair.before)) {

                const order: SolutionOrderLegDto = {
                    ticker: pair.after.ticker,
                    qty: pair.after.qty,
                    underlying: pair.after.underlying,
                    type: pair.after.type,
                    strike: isVoid(pair.after.strike) ? null : pair.after.strike.toString(),
                    expiration: pair.after.expiration,
                    role: pair.after.role,
                    action: pair.after.qty < 0 ? 'Sell To Open' : 'Buy To Open'
                };

                return order;

            }

            if (isVoid(pair.after)) {

                const order: SolutionOrderLegDto = {
                    ticker: pair.before.getTicker(),
                    qty: pair.before.qty * -1,
                    underlying: pair.before.underlyingSymbol,
                    type: pair.before.type,
                    strike: isVoid(pair.before.strike) ? null : pair.before.strike.toString(),
                    expiration: makeGuiFriendlyExpirationDate(pair.before.selectedExpiration.optionExpirationDate),
                    role: pair.before.role,
                    action: pair.before.qty < 0 ? 'Buy To Close' : 'Sell To Close'
                };

                return order;

            }

            if (pair.after.ticker !== pair.before.getTicker()) {

                const closing: SolutionOrderLegDto = {
                    ticker: pair.before.getTicker(),
                    qty: pair.before.qty * -1,
                    underlying: pair.before.underlyingSymbol,
                    type: pair.before.type,
                    strike: pair.before.strike + '',
                    expiration: makeGuiFriendlyExpirationDate(pair.before.selectedExpiration.optionExpirationDate),
                    role: pair.before.role,
                    action: pair.before.qty > 0 ? 'Sell To Close' : 'Buy To Close'
                };

                const opening: SolutionOrderLegDto = {
                    ticker: pair.after.ticker,
                    qty: pair.after.qty,
                    underlying: pair.after.underlying,
                    type: pair.after.type,
                    strike: pair.after.strike + '',
                    expiration: pair.after.expiration,
                    role: pair.after.role,
                    action: pair.before.qty > 0 ? 'Buy To Open' : 'Sell To Open'
                }

                return [opening, closing];

            }

            const ticker = pair.after.ticker;

            const qty = pair.after.qty - pair.before.qty;

            if (Math.abs(qty) === 0) {
                return undefined;
            }

            const underlying = pair.after.underlying;
            const type = pair.after.type;
            const strike = isVoid(pair.after.strike) ? null : pair.after.strike.toString();
            const expiration = pair.after.expiration;
            const role = pair.after.role;
            const action: SolutionOrderLegActions = qty < 0 ? 'Sell To Close' : 'Buy To Open';

            const diffOrder: SolutionOrderLegDto = {
                ticker,
                qty,
                underlying,
                type,
                strike,
                expiration,
                role,
                action
            };

            return diffOrder;

        }).filter(x => !isVoid(x));

        return orders;
    }

    private async onAfterStateDetalizationReady() {
        const data = this._afterStateDetalizationService.getData();
        await this.afterStateDetalizationDialog.show(data);
        this._afterStateDetalizationService.resolve();
    }

    private markChangedPositions(beforeState: SolutionPositionDto[], afterState: SolutionPositionDto[]) {

        const roles: CashFlowStrategyRole[] = [
            'ShortOption',
            'SpreadLongLeg',
            'SpreadShortLeg',
            'SecondSpreadLongLeg',
            'SecondSpreadShortLeg',
            'ProtectiveOption',
            'SecondProtectiveOption'
        ];

        const pairs = roles.map(role => {
            const before = beforeState.find(x => x.role === role);
            const after = afterState.find(x => x.role === role);

            return {before, after};
        });

        const secondSpreadBefore = beforeState.some(x => x.role.startsWith('SecondSpread'));
        const secondSpreadAfter = afterState.some(x => x.role.startsWith('SecondSpread'));

        const secondSpreadChanged = (secondSpreadBefore && !secondSpreadAfter)
            || (!secondSpreadBefore && secondSpreadAfter);

        pairs.forEach(p => {
            if (isVoid(p.before) && isVoid(p.after)) {
                return;
            }

            if (isVoid(p.after)) {
                return;
            }

            if (isVoid(p.before)) {
                p.after.changing = true;
                return;
            }

            if (p.before.ticker !== p.after.ticker) {
                p.after.changing = true;
            }

            const isProtective = p.before.role.indexOf('Protective') >= 0
                || p.after.role.indexOf('Protective') >= 0;

            if (isProtective && secondSpreadChanged) {
                p.after.changing = true;
            }
        });
    }

    @DetectMethodChanges({isAsync: true})
    async loadDataZonesGrid(row?: PriceboxRow) {

        if (isVoid(this.zonesGridSettingsDialog)) {
            return;
        }

        if (isVoid(this.zonesGridSection)) {
            return;
        }

        let zgs: ZonesGridSettings = this.zonesGridSettingsDialog.getSettings(this.settingsSection.underlying);

        let restoreZonesGrid = true;

        if (!isVoid(row)) {
            try {
                zgs = await this.zonesGridSettingsDialog.show(this.settingsSection.underlying);
            } catch {
                return;
            }
        } else {
            restoreZonesGrid = false;
            row = this.zonesGridSection.loadedRow;
        }

        const adjustmentPricingSettings = this.getAdjustmentPricingSettingsObject('Zones Grid');

        if (!isVoid(adjustmentPricingSettings.errors)) {
            adjustmentPricingSettings.errors.forEach(x => this._toastr.error(x));
            return;
        }

        this.resetZonesGrid();

        if (restoreZonesGrid) {
            this.zonesGridColCollapsed = false;
        }

        let logicalId: any = row.columns[0].adjustments[0].logicalId;
        if (row.columns[0].adjustments.length > 1) {
            logicalId = logicalId + '+' + row.columns[0].adjustments[1].logicalId;
        }

        adjustmentPricingSettings.settings.forEach(x => {
            x.zonesGridRange = zgs.range;
            x.zonesGridRangeStep = zgs.step;
            x.zonesGridAdjustment = logicalId;
            x.zonesGridRangeDown = zgs.rangeDown;
        });

        const requestId = 'zones_' + getShortUUID();

        this._pendingRequestId.push(requestId);

        const qry = new GetAdjustmentSolutions(
            requestId,
            adjustmentPricingSettings.settings,
        );

        this.isDataLoading = true;

        if (this.settingsSection.globalSettings.useTheoreticalPrices) {
            const theoreticalPriceIv = this.settingsSection.globalSettings.theoreticalPriceIv;

            let volatilities = `total=${theoreticalPriceIv}`;

            const cmd = new SetImpliedVolatilityShell(
                this.settingsSection.underlying,
                volatilities
            );

            await this._shellClient.processCommand(cmd);
        }

        try {

            await this._shellClient.processCommand(qry);

            this.zonesGridSection.loadedRow = row;

            if (!isVoid(adjustmentPricingSettings)) {
                if (!isVoid(adjustmentPricingSettings.settings)) {
                    this.zonesGridSection.lastUsedSettings = adjustmentPricingSettings.settings;
                }
            }

        } catch (e) {
            removeFromArray(this._pendingRequestId, requestId);
            this._toastr.error('"Load Zones Grid Pricing Data" operation completed with errors');
            this.isDataLoading = false;
        }
    }

    isPriceBoxTheoretical(): boolean {
        return this._strategyCodeIndex.isPriceBoxTheoretical();
    }

    isZonesGridTheoretical(): boolean {
        return this._strategyCodeIndex.isZonesGridTheoretical();
    }

    private fillTheBlanks(adjustments: CashFlowAdjustment[], isZonesData: boolean) {
        if (this.settingsSection.globalSettings.theoreticalPriceMode !== 'Fill Blanks') {
            return;
        }

        const target = this.settingsSection.globalSettings.theoreticalPriceTarget;
        if (target !== 'All') {
            if (isZonesData && target !== 'Zones Grid') {
                return;
            }

            if (!isZonesData && target !== 'Price Box') {
                return;
            }
        }

        const blankAdjustments = adjustments
            .filter(x => !x.isEmpty)
            .filter(x => isVoid(x.price));

        console.log(`found ${blankAdjustments.length} empty adjustments`);

        if (blankAdjustments.length === 0) {
            this._toastr.info('No blank adjustments found', 'Theoretical Prices - "Fill Blanks"');
            return;
        }

        this._toastr.info(`${blankAdjustments.length} blank adjustments found. Replacing missing quotes with theoretical prices...`
            , 'Theoretical Prices - "Fill Blanks"');

        blankAdjustments.forEach(adj => {

            let originalStrategyCode = adj.optionStrategyCode;
            let updatedStrategyCode = originalStrategyCode;

            console.group(`processing blank adjustment: ${adj.uniqueAdjustmentSpecificationId}`);

            console.log(`original strategy code: ${originalStrategyCode}`);

            const replaced: { [ix: string]: string } = {};

            function updateOrderBlanks(legs: SolutionOrderLegDto[]) {
                legs.forEach(leg => {

                    if (!isVoid(leg.price)) {
                        return;
                    }

                    console.log(`detected blank leg: ticker=${leg.ticker}`);

                    const legOriginalTicker = leg.ticker;

                    const legTheoTicker = leg.ticker + ' !0';

                    updatedStrategyCode = updatedStrategyCode.replace(legOriginalTicker, legTheoTicker);

                    console.log(`updated strategy code: ${updatedStrategyCode}`);

                    leg.ticker = legTheoTicker;

                    replaced[legOriginalTicker] = legTheoTicker;
                });
            }

            updateOrderBlanks(adj.mainLegs);
            updateOrderBlanks(adj.linkedLegs);
            updateOrderBlanks(adj.secondLinkedLegs);
            updateOrderBlanks(adj.thirdLinkedLegs);

            function updatePositionBlanks(poses: SolutionPositionDto[]) {
                poses.forEach(pos => {
                    const replaceTo = replaced[pos.ticker];
                    if (isVoid(replaceTo)) {
                        return;
                    }
                    pos.ticker = replaceTo;
                });
            }

            updatePositionBlanks(adj.beforeState);
            updatePositionBlanks(adj.afterState);

            adj.optionStrategyCode = updatedStrategyCode;

            console.log(`final strategy code: ${updatedStrategyCode}`);

            console.groupEnd();
        });

        if (!isZonesData) {
            this._quoteIndex.addData(blankAdjustments);
            this._strategyCodeIndex.addData(blankAdjustments);
        } else {
            this._strategyCodeIndex.addDataZones(blankAdjustments);
            this._quoteIndex.addDataZones(blankAdjustments);
        }

        this._toastr.info('Blank adjustments are filled with theoretical prices', 'Theoretical Prices - "Fill Blanks"');
    }

    getPriceBoxSectionHeaderVolatility() {
        return this._priceboxSectionHeaderVolatility;
    }

    getZonesGridSectionHeaderVolatility() {
        return this._zonesGridSectionHeaderVolatility;
    }

    @DetectMethodChanges()
    zonesGridChangeLinkState() {
        this.isZonesGridLinked = !this.isZonesGridLinked;
    }

    zonesGridClear() {
        this.resetZonesGrid();
    }

    areSettingsDifferent(): boolean {
        if (isVoid(this.zonesGridSection)) {
            return false;
        }

        if (isVoid(this.priceboxSection.lastUsedSettings)) {
            return false;
        }

        if (isVoid(this.priceboxSection.rows)) {
            return false;
        }

        if (isVoid(this.zonesGridSection.lastUsedSettings)) {
            return false;
        }

        if (isVoid(this.zonesGridSection.rows)) {
            return false;
        }

        const pb = JSON.stringify(this.priceboxSection.lastUsedSettings || '{}');

        const zg = JSON.stringify(this.zonesGridSection.lastUsedSettings || '{}');

        return pb !== zg;
    }

    getPriceboxTableWidth() {
        let width = this.getPriceboxSectionWidth();
        return width + '%';
    }

    getPriceboxHeaderWidth() {
        return this.getPriceboxSectionWidth();
    }

    private getPriceboxSectionWidth(): number {
        let width = 100;
        if (this.priceboxSection.showZonesGridArrow) {
            width = 99;
            if (!this.zonesGridColCollapsed) {
                width = 98;
            }
        }

        return width;
    }

    @DetectMethodChanges()
    toggleShowHedges() {
        this.showHedges = !this.showHedges;
    }
}
