import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
    checkClipboardPermissions,
    arraysEqual,
    DetectMethodChanges,
    DxValueChanged,
    isValidNumber,
    isVoid
} from "../../utils";
import {HedgeOrderGroup} from "../data-model/hedge-order-group";
import {HedgePositionGroup} from "../data-model/hedge-position-group";
import {HedgePositionTransaction} from "../data-model/hedge-position-transaction";
import {HedgeStateTransaction} from "../data-model/hedge-state-transaction";
import {MessageBusService} from "../../message-bus.service";
import {filter} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {
    ComboHighlightedItem,
    ComboHighlightedUIMessage,
    MultiLegOrderDataUIMessage
} from "../../ui-messages/ui-messages";
import {ClipboardService} from "../../clipboard.service";
import {MarketSide} from "../../trading-model/market-side.enum";
import {DateTime} from "luxon";
import {parseOptionTicker} from "../../options-common/options.model";
import {Subscription} from "rxjs";
import {HedgeOrder} from "../data-model/hedge-order";
import {getGroupTotal, getOrderGroupSubTotal, getOrderGroupTotal} from "../hg-totals-calculators";
import {UserSettingsService} from "../../user-settings.service";
import {OrdersRegroupDialogComponent} from "../../orders-regroup-dialog/orders-regroup-dialog.component";
import {OrderToRegroupWrapper} from "../../orders-regroup-dialog/OrderToRegroupWrapper";
import {OrdersGroupToRegroup} from "../../orders-regroup-dialog/OrdersGroupToRegroup";
import {RegroupOrdersConfig} from "../../orders-regroup-dialog/RegroupOrdersConfig";
import {RegroupedOrders} from "../../orders-regroup-dialog/RegroupedOrders";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";

type Destinations = 'ETS' | 'TOS Desktop' | 'TOS Web';

@Component({
    selector: 'ets-hg-transaction-popup',
    templateUrl: 'hg-transaction-popup.component.html',
    styleUrls: [
        './hg-transaction-popup.component.scss',
        '../hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgTransactionPopupComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService,
        private readonly _toastr: ToastrService,
        private readonly _clipboardService: ClipboardService,
        private readonly _userSettingsService: UserSettingsService
    ) {
    }

    private _packageId: string;

    private _isInverted: () => boolean;

    private _subscriptions: Subscription[] = [];

    portfolio: ApgPortfolio;

    @Input() context: 'pkg-cmprsn' | undefined;

    @ViewChild(OrdersRegroupDialogComponent)
    orderRegroupDialog: OrdersRegroupDialogComponent;

    visible = false;

    isDoubleMode = false;

    transactions: HedgeStateTransaction[] = [];

    firstSidePositionsVisible = true;

    secondSidePositionsVisible = true;

    portfolioDefaultQty?: number;

    destinations: Destinations[] = [
        'ETS',
        'TOS Desktop',
        'TOS Web'
    ];

    callDestination: Destinations = 'ETS';
    putDestination: Destinations = 'ETS';

    callOrdersToCopyList = [];
    putOrdersToCopyList = [];

    callOrderToCopy: string;
    putOrderToCopy: string;

    get invertMultiplier(): number {
        return (isVoid(this._isInverted) || !this._isInverted())
            ? 1
            : -1;
    }

    get height() {
        return '95vh';
    }

    get width() {
        return this.transactions.length == 2 ? '99vw' : '50vw';
    }

    get maxWidth() {
        return this.transactions.length === 2 ? '1650' : '820px';
    }

    get maxHeight() {
        return '99vh';
    }

    ngOnInit() {
        this._subscriptions.push(
            this._messageBus.of('Hg.RefreshQuotes')
                .pipe(
                    filter(x => this.visible === true)
                )
                .subscribe(x => this._changeDetector.detectChanges())
        );
    }

    @DetectMethodChanges()
    onChange() {
    }

    @DetectMethodChanges()
    show(portfolio: ApgPortfolio, defaultQty: number, transactions: HedgeStateTransaction[], isDoubleMode: boolean, pkgId?: string, isInverted?: () => boolean): void {

        this.portfolio = portfolio;

        this.isDoubleMode = isDoubleMode;

        this._packageId = pkgId;

        this.transactions = transactions;

        if (!isDoubleMode) {
            this.transactions = transactions.filter(x => !isVoid(x.orderTransactions));
        }

        this.portfolioDefaultQty = defaultQty;

        this.fillDropdowns(transactions);

        this._isInverted = isInverted;

        this.visible = true;
    }

    @DetectMethodChanges()
    onClosed() {
        this.visible = false;
    }

    getGroupSubTotal(group: HedgePositionGroup) {
        const total = getGroupTotal(group, this.portfolioDefaultQty);
        return total;
    }

    getGrandTotal(transaction: HedgeStateTransaction) {
        if (transaction.positionTransactions.every(x => x.noChanges)) {
            return undefined;
        }

        const gt = transaction.positionTransactions.map(x => {
            if (x.noChanges) {
                return 0;
            }
            const beforeSum = this.getGroupSubTotal(x.beforeState) || 0;
            const afterSum = this.getGroupSubTotal(x.afterState) || 0;
            return beforeSum + afterSum;
        }).reduce((a, b) => a + b, 0);

        return gt;
    }

    getRowTotal(row: HedgePositionTransaction) {

        if (row.noChanges) {
            return undefined;
        }

        const beforeSum = this.getGroupSubTotal(row.beforeState) || 0;

        const afterSum = this.getGroupSubTotal(row.afterState) || 0;

        const sum = beforeSum + afterSum;

        if (sum === 0) {

            const beforeStatePos = (isVoid(row.beforeState) ? [] : row.beforeState.positions) || [];

            const afterStatePos = (isVoid(row.afterState) ? [] : row.afterState.positions) || [];

            const beforeTickers =
                beforeStatePos.map(x => `${x.qty}${x.expiration}${x.type}${x.strike}`);

            const afterTickers =
                afterStatePos.map(x => `${x.qty}${x.expiration}${x.type}${x.strike}`);

            const areEqual = arraysEqual(beforeTickers, afterTickers);

            if (areEqual) {
                return undefined;
            }
        }

        return sum;
    }

    getOrderGroupTotal(orderGroup: HedgeOrderGroup) {
        const grpTotal = getOrderGroupTotal(orderGroup, this.portfolioDefaultQty);
        return grpTotal;
    }

    getPositionSectionVisibility(side: 'Calls' | 'Puts') {
        if (side === 'Calls') {
            return this.firstSidePositionsVisible;
        }

        if (side === 'Puts') {
            return this.secondSidePositionsVisible;
        }

        return undefined;
    }

    @DetectMethodChanges()
    onPositionSectionVisibilityChanged(side: 'Calls' | 'Puts') {
        if (side === 'Calls') {
            this.firstSidePositionsVisible = !this.firstSidePositionsVisible;
        } else if (side === 'Puts') {
            this.secondSidePositionsVisible = !this.secondSidePositionsVisible;
        }
    }

    getPriceClass(price: number) {
        if (!isValidNumber(price, true)) {
            return undefined;
        }

        return price > 0 ? 'credit' : 'debit';
    }

    getDestination(side: 'Calls' | 'Puts') {
        if (side !== 'Calls' && side !== 'Puts') {
            throw Error('Option Type Not Supported');
        }
        return side === 'Calls' ? this.callDestination : this.putDestination;
    }

    getOrdersToCopyList(side: 'Calls' | 'Puts') {
        if (side !== 'Calls' && side !== 'Puts') {
            throw Error('Option Type Not Supported');
        }
        return side === 'Calls' ? this.callOrdersToCopyList : this.putOrdersToCopyList;
    }

    getOrderToCopy(side: 'Calls' | 'Puts') {
        return side === 'Calls' ? this.callOrderToCopy : this.putOrderToCopy;
    }

    async onCopyClicked(side: 'Calls' | 'Puts') {

        if (side !== 'Calls' && side !== 'Puts') {
            throw Error(`Option Type "${side || 'N/A'}" Not Supported`);
        }

        const destination = this.getDestination(side);

        try {
            if (destination === 'ETS') {
                this.copyOrdersToEts(side);
            } else if (destination === 'TOS Desktop') {
                await this.copyOrdersToTOSDesktop(side);
            } else if (destination === 'TOS Web') {
                await this.copyOrdersToTOSWeb(side);
            } else {
                this._toastr.error('Unknown Destination');
                return;
            }

            this.saveAfterState(side);

        } catch (e) {
            console.error(e);
            this._toastr.error('Could not copy to clipboard. Please check browser permissions, or contact system administrator');
        }
    }

    @DetectMethodChanges()
    onOrderToCopySelected(args: DxValueChanged<string>, side: 'Calls' | 'Puts') {
        if (side === 'Calls') {
            this.callOrderToCopy = args.value;
        } else if (side === 'Puts') {
            this.putOrderToCopy = args.value;
        }
    }

    @DetectMethodChanges()
    onDestinationSelected(args: DxValueChanged<Destinations>, side: 'Calls' | 'Puts') {

        if (isVoid(side)) {
            return;
        }

        if (side === 'Calls') {
            this.callDestination = args.value;
        } else if (side === 'Puts') {
            this.putDestination = args.value;
        }
        const key = `hg.last-used-destination.${side.toLowerCase()}`;
        this._userSettingsService.setValue(key, args.value);
    }

    getOrderPackageTotal(orderPkg: HedgeOrder[]) {
        const subTotal = getOrderGroupSubTotal(orderPkg);
        return subTotal;
    }

    @DetectMethodChanges()
    copyToHedgingGrid() {

        const data: ComboHighlightedUIMessage[] = [];

        for (let transaction of this.transactions) {

            const positions = transaction.positionTransactions
                .filter(pt => !pt.noChanges)
                .flatMap(pt => {
                    return pt.afterState.positions;
                });


            const items = positions.map(x => {

                const qty = x.qty * this.invertMultiplier;

                const item: ComboHighlightedItem = {
                    netPosition: Math.abs(qty),
                    side: qty > 0 ? MarketSide.Buy : (qty < 0 ? MarketSide.Sell : MarketSide.Flat),
                    ticker: x.ticker,
                    underlying: x.asset
                } as any;
                return item;
            });

            const msg: ComboHighlightedUIMessage = {
                strategyName: positions[0].label,
                items
            };

            data.push(msg);
        }

        this._clipboardService.put('hg.pkg-cmprsn', data);

        this._toastr.success('Hedges Have Been Copied to Clipboard!');
    }

    @DetectMethodChanges()
    copySideToHedgingGrid(side: 'Calls' | 'Puts') {

        if (isVoid(this.transactions)) {
            return;
        }

        const hedgeStateTransaction = this.transactions.find(x => x.side === side);

        if (isVoid(hedgeStateTransaction)) {
            return;
        }

        const positions = hedgeStateTransaction.positionTransactions
            .filter(pt => !pt.noChanges)
            .flatMap(pt => {
                return pt.afterState.positions;
            });


        const items = positions.map(x => {
            const item: ComboHighlightedItem = {
                netPosition: Math.abs(x.qty),
                side: x.qty > 0 ? MarketSide.Buy : (x.qty < 0 ? MarketSide.Sell : MarketSide.Flat),
                ticker: x.ticker,
                underlying: x.asset
            } as any;
            return item;
        });

        const msg: ComboHighlightedUIMessage = {
            strategyName: positions[0].label,
            items
        };

        this._clipboardService.put('hg.pkg-cmprsn', [msg]);

        this._toastr.success(`"${hedgeStateTransaction.side}" Hedges Have Been Copied to Clipboard!`);
    }

    getOrderActionText(text: string): string {
        if (isVoid(text)) {
            return text;
        }

        if (isVoid(this._isInverted) || !this._isInverted()) {
            return text;
        }

        if (text.indexOf('Buy') !== -1) {
            text = text.replace('Buy', 'Sell');
        } else if (text.indexOf('Sell') !== -1) {
            text = text.replace('Sell', 'Buy');
        }

        return text;
    }

    private saveAfterState(side: "Calls" | "Puts") {
        const sideToCopy = this.transactions.find(x => x.side === side);

        let slot: 'first' | 'second' = 'first';

        if (this.isDoubleMode) {
            if (side === 'Puts') {
                slot = 'second';
            }
        }

        const hedgePositionGroups = sideToCopy
            .positionTransactions
            .map(x => x.afterState);

        const positions = hedgePositionGroups
            .filter(x => !isVoid(x))
            .flatMap(x => x.positions);


        this._messageBus.publish({
            topic: 'Hg.SaveAfterState',
            payload: {
                positions,
                slot,
                packageId: this._packageId
            }
        });

        this._toastr.success('Orders Copied to Clipboard');
    }

    private fillDropdowns(transactions: HedgeStateTransaction[]) {

        this.callOrdersToCopyList = [];
        this.putOrdersToCopyList = [];

        transactions.forEach(trns => {
            const arr = trns.side === 'Calls' ? this.callOrdersToCopyList : this.putOrdersToCopyList;
            const labels = trns.orderTransactions.map(x => x.label);

            if (labels.length > 0) {
                arr.push('All');
                arr.push(...labels);
            }
        });

        if (this.callOrdersToCopyList.length > 0) {
            this.callOrderToCopy = this.callOrdersToCopyList[0];
        }

        if (this.putOrdersToCopyList.length > 0) {
            this.putOrderToCopy = this.putOrdersToCopyList[0];
        }

        let callKey = `hg.last-used-destination.calls`;
        let putKey = `hg.last-used-destination.puts`;

        const cDest = this._userSettingsService.getValue(callKey);
        const pDest = this._userSettingsService.getValue(putKey);

        this.callDestination = cDest as any;
        this.putDestination = pDest as any;
    }

    private copyOrdersToEts(side: "Calls" | "Puts", regrouped?: RegroupedOrders[]) {

        const orderGroups = this.getOrderGroupsToCopy(side);

        let orders = orderGroups
            .flatMap(x => x.balancedOrders);

        if (!isVoid(regrouped)) {
            orders = regrouped.map(x => x.orders as HedgeOrder[]);
        }

        const orderLegsGroups = orders.map(grp => {

            const mappedOrders = grp.map(o => {
                const marketSide = o.action.indexOf('Buy') >= 0 ? MarketSide.Buy : MarketSide.Sell;
                const item: ComboHighlightedItem = {
                    ticker: o.ticker,
                    underlying: o.asset,
                    side: marketSide,
                    itemType: null,
                    netPosition: o.qty
                } as any;

                return item;
            });

            const comboMsg: ComboHighlightedUIMessage = {
                items: mappedOrders,
                orderParams: {
                    orderQty: 1
                }
            }

            return comboMsg;
        });

        if (orderGroups.length > 0) {

            if (orderLegsGroups.length > 1) {

                const main = orderLegsGroups[0];
                const linked = orderLegsGroups[1];
                const secondLinked = orderLegsGroups[2];
                const thirdLinked = orderLegsGroups[3];

                const msg: MultiLegOrderDataUIMessage = {
                    main,
                    linked,
                    secondLinked,
                    thirdLinked,
                    linkType: 'OTO',
                    cashFlowSpec: {} as any,
                };

                this._clipboardService.put('multi-combo', msg);

            } else {

                const msg: ComboHighlightedUIMessage = {
                    items: orderLegsGroups[0].items,
                    orderParams: {
                        orderQty: 1
                    }
                };

                this._clipboardService.put('combo', msg);
            }
        } else {
            throw new Error('No Orders To Copy');
        }

    }

    private async copyOrdersToTOSDesktop(side: 'Calls' | 'Puts', regrouped?: RegroupedOrders[]) {

        const ordersToCopy = this.getOrderGroupsToCopy(side);

        let orders = ordersToCopy
            .flatMap(x => x.balancedOrders)
            .map(x => {
                return {
                    orders: x,
                    limitPrice: null
                }
            });

        if (!isVoid(regrouped)) {
            orders = regrouped.map(x => {
                return {
                    orders: x.orders as HedgeOrder[],
                    limitPrice: x.limitPrice
                }
            });
        }


        const lines = orders
            .map(x => this.makeTosDesktopOrderLine(x));

        const text = lines.join('\n');

        // @ts-ignore
        const result = await checkClipboardPermissions();

        if (result) {
            window.focus();
            await navigator.clipboard.writeText(text);
        } else {
            throw Error('Could not copy to clipboard. Please check browser permissions');
        }
    }

    private makeTosDesktopOrderLine(args: {orders: HedgeOrder[], limitPrice?: number}): string {

        if (isVoid(args?.orders)) {
            return undefined;
        }

        const orders = args.orders;
        const limitPrice = args.limitPrice;

        const transformedLegGroups = orders
            .map(order => {
                const qty = order.qty;
                const date = DateTime.fromFormat(order.expiration, 'yyyy-MM-dd')
                    .toFormat('dd MMM yy')
                    .toUpperCase();
                const strike = order.strike;
                const type = order.type.toUpperCase();
                const price = order.price;

                return {
                    qty,
                    date,
                    strike,
                    type,
                    price
                };
            });

        const underlying = orders[0].asset;

        const quantities = transformedLegGroups.map(x => x.qty).join('/');

        const dates = transformedLegGroups.map(x => x.date).join('/');

        const strikes = transformedLegGroups.map(x => x.strike).join('/');

        const types = transformedLegGroups.map(x => x.type).join('/');

        const comboPrice = transformedLegGroups
            .reduce((p, c) => p + c.price, 0) * -1;

        if (!isValidNumber(comboPrice)) {
            return undefined;
        }


        let line: string;

        const px = isValidNumber(limitPrice) ? Math.abs(limitPrice)+'' : ''

        if (underlying === 'SPX') {
            line = `BUY 1 ${quantities} CUSTOM ${underlying} 100 (Weeklys) ${dates} ${strikes} ${types} @${px} LMT GTC`;
        } else {
            line = `BUY 1 ${quantities} CUSTOM ${underlying} 100 ${dates} ${strikes} ${types} @${px} LMT GTC`;
        }

        return line;
    }

    private getOrderGroupsToCopy(side: 'Calls' | 'Puts'): HedgeOrderGroup[] {

        if (side !== 'Calls' && side !== 'Puts') {
            throw new Error('Wrong side');
        }

        const trans = this.transactions.find(x => x.side === side);

        const orderToCopy = side === 'Calls' ? this.callOrderToCopy : this.putOrderToCopy;

        const orderGroups = orderToCopy === 'All'
            ? trans.orderTransactions
            : [trans.orderTransactions.find(x => x.label === orderToCopy)];

        if (isVoid(orderGroups)) {
            throw new Error(`Cannot find order group: ${orderToCopy} in ${side}`);
        }

        return orderGroups;
    }

    private async copyOrdersToTOSWeb(side: 'Calls' | 'Puts') {

        const orderGroupsToCopy = this.getOrderGroupsToCopy(side);

        if (orderGroupsToCopy.length === 0) {
            throw new Error('No Orders To Copy');
        }

        if (orderGroupsToCopy.length > 1) {
            throw new Error('TOS Web Only Accepts one Order at a Time');
        }

        const group = orderGroupsToCopy[0];

        const symbol = `symbol=${group.orders[0].asset}`;

        const tosLeg = group.orders.map((leg, ix) => {
            const tosLegNo = `leg${ix + 1}`;
            const ot = parseOptionTicker(leg.ticker);
            const expiration = DateTime.fromFormat(ot.expiration, 'yyyy-MM-dd').toFormat('yyMMdd');
            const tosTicker = `.${ot.expirationTicker.substring(1)}${expiration}${ot.type[0].toUpperCase()}${ot.strike}`;
            const actionSign = leg.action.indexOf('Buy') >= 0 ? 1 : -1;
            const fullLegDescriptor = `${tosLegNo}=${leg.qty},${tosTicker}`;

            return fullLegDescriptor;
        });

        const oType = 'orderType=LIMIT';
        const oPrice = 'limitPrice=';
        const oTif = 'orderTif=GTC';

        const host = 'https://trade.thinkorswim.com/trade';

        const request = `${host}?${symbol}&${tosLeg.join('&')}&${oType}&${oPrice}&${oTif}`;

        await navigator.clipboard.writeText(request);

        return true;
    }

    showRegroupDialog(side: 'Calls' | 'Puts') {
        const orderGroupsToCopy = this.getOrderGroupsToCopy(side);

        const groups = orderGroupsToCopy
            .map(x => {
                const label = x.label;
                const color = x.color;
                const wrappers = x.orders.map(o => {
                    const w = new OrderToRegroupWrapper(o, color);
                    return w;
                });
                const grp = new OrdersGroupToRegroup(label as any, wrappers);
                return grp;
            });


        const cfg: RegroupOrdersConfig = {
            orders: groups,
            copyCallback: async (args: RegroupedOrders[]) => {
                try {

                    const destination = this.getDestination(side);

                    if (destination === 'ETS') {
                        this.copyOrdersToEts(side, args);
                    } else if (destination === 'TOS Desktop') {
                        await this.copyOrdersToTOSDesktop(side, args);
                    } else if (destination === 'TOS Web') {
                        await this.copyOrdersToTOSWeb(side);
                    } else {
                        this._toastr.error('Not Supported Destination Selected');
                    }

                    this.saveAfterState(side);

                } catch {
                    this._toastr.error('"Copy Orders" Operation Completed With Errors');
                }
            },
            portfolio: this.portfolio,
            context: 'hg'
        };

        this.orderRegroupDialog.show(cfg)
    }
}
