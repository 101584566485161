import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    DetectMethodChanges,
    DxValueChanged,
    findHCF,
    getActionClass,
    getBucketRoleClass,
    getQtyClass, isValidNumber,
    isVoid
} from "../utils";
import {RegroupOrdersConfig} from "./RegroupOrdersConfig";
import {DropZone} from "./DropZone";
import {OrdersGroupToRegroup} from "./OrdersGroupToRegroup";
import {ToastrService} from "ngx-toastr";
import {SolutionOrderLegDto} from "../adjustment-pricing-grid/model/SolutionOrderLegDto";
import {DropZoneOrder, OrderToRegroupWrapper} from "./OrderToRegroupWrapper";
import {SolutionPositionDto} from "../adjustment-pricing-grid/model/SolutionPositionDto";
import {RegroupedOrders} from "./RegroupedOrders";
import * as Enumerable from "linq";
import {LastQuoteCacheService} from "../last-quote-cache.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";
import {HedgePositionsService} from "../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {HedgePosition} from "../hedging-grid/data-model/hedge-position";
import {makeOptionTickerDisplayName, parseOptionTicker} from "../options-common/options.model";
import {tick} from "@angular/core/testing";

@Component({
    selector: 'ets-orders-regroup-dialog',
    templateUrl: 'orders-regroup-dialog.component.html',
    styleUrls: ['./orders-regroup-dialog.component.scss']
})

export class OrdersRegroupDialogComponent implements OnInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _apgDataService: ApgDataService,
        private readonly _hedgePositionsService: HedgePositionsService
    ) {
    }

    private _reverseTicker: string;

    private _config: RegroupOrdersConfig;

    portfolioDefaultQty: number;

    visible = false;

    loading = false;

    splitQty = false;

    get orderGroups(): OrdersGroupToRegroup[] {
        return this._config?.orders;
    }

    readonly dropZones: DropZone[] = [];

    ngOnInit() {
    }

    @DetectMethodChanges({isAsync: true})
    async show(config: RegroupOrdersConfig) {
        this._config = config;

        this._config.orders.forEach(x => {
            const dz = new DropZone();
            dz.title = x.title;
            this.dropZones.push(dz);
        });

        const defaultQtyForPortfolio = await this._apgDataService
            .getDefaultQtyForPortfolio(config.portfolio);

        this.portfolioDefaultQty = defaultQtyForPortfolio;

        this.visible = true;
    }

    getOrderTitle(zone: DropZone) {
        const ix = this.dropZones.indexOf(zone);
        if (ix < 0) {
            return 'N/A';
        }

        if (ix === 0) {
            return 'Main';
        }

        return `Linked #${ix}`;
    }

    @DetectMethodChanges()
    onHidden() {
        this.dropZones.length = 0;
        this.splitQty = false;
        this.visible = false;
    }

    onDragStart($event: DragEvent, order: OrderToRegroupWrapper) {
        $event.dataTransfer.setData("text", `${order.ticker},${order.group.title}`);
    }

    @DetectMethodChanges()
    onDrop($event: DragEvent, zone: DropZone) {

        $event.preventDefault();

        if (isVoid(zone)) {
            return;
        }

        this.loading = true;

        const id = $event.dataTransfer.getData("text");

        const parts = id.split(',');

        if (parts.length !== 2) {
            this._toastr.error('Order Not Recognized');
            return;
        }

        const ticker = parts[0];

        const title = parts[1];

        const self = this;

        setTimeout(() => {
            try {

                if (!ticker || !title) {
                    return;
                }

                if (zone.items.length >= 4) {
                    this._toastr.error('Order cannot have more than 4 legs');
                    return;
                }

                const item = this.orderGroups
                    .find(x => x.title === title)
                    ?.orders.find(x => x.ticker === ticker);

                if (isVoid(item)) {
                    this._toastr.error('Drop operation cannot be completed', 'Error');
                    return;
                }


                const index = zone.items.findIndex(x => x.ticker === ticker);

                if (index >= 0) {
                    this._toastr.error('Selected leg already added to this order', 'Error');
                    return;
                }

                const copy = new DropZoneOrder(item);
                copy.group = item.group;

                zone.add(copy);

                (item as any).moved = true;
            } finally {
                self.relabelLegs()
                    .then(() => {
                        this.loading = false;
                        this._changeDetector.detectChanges();
                    });
            }
        });
    }

    onDragOver($event: DragEvent) {
        $event.preventDefault();
    }

    @DetectMethodChanges()
    addDropZone() {
        if (this.dropZones.length > 4) {
            this._toastr.error('Cannot have more than 4 orders');
            return;
        }
        const dz = new DropZone();
        dz.title = this.dropZones.length === 0
            ? 'Main'
            : `Linked #${this.dropZones.length}`;
        this.dropZones.push(dz);
    }


    @DetectMethodChanges()
    removeOrder(order: DropZoneOrder) {
        this.removeOrderSilent(order);
    }

    private removeOrderSilent(order: DropZoneOrder) {
        this.dropZones.forEach(dz => {
            const ix = dz.items.indexOf(order);
            if (ix < 0) {
                return;
            }
            dz.items.splice(ix, 1);
        });

        order.onRemoved();
    }

    getActionClass(order: SolutionOrderLegDto, isEvenRow: boolean) {
        const classes = [];

        const actionClass = getActionClass(order);

        classes.push(actionClass);

        if (isEvenRow) {
            classes.push('couple-start');
        } else {
            classes.push('couple-end');
        }

        return classes;
    }

    getPositionRoleClass(pos: SolutionPositionDto | SolutionOrderLegDto): string {
        return getBucketRoleClass(pos);
    }

    //
    getQtyClass(position: any): any {
        return getQtyClass(position);
    }

    @DetectMethodChanges()
    removeZone(zone: DropZone) {

        const slice = zone.items.slice();

        slice.forEach(item => {
            this.removeOrderSilent(item);
        });

        const ix = this.dropZones.indexOf(zone);

        if (ix < 0) return;

        this.dropZones.splice(ix, 1);
    }

    copySingleOrder(zone: DropZone) {

        if (!isVoid(this._reverseTicker)) {
            const optionTicker = parseOptionTicker(this._reverseTicker);
            const tickerDisplayName = makeOptionTickerDisplayName(optionTicker);
            this._toastr.error(`One Of The Legs Among Regrouped Is A Reversing Trade! (${tickerDisplayName}) <br> Reversing Orders Will Be Rejected by The Broker. Please, split trade quantity so that one order close existing positions, and second open desired one`, 'Attention',
                {closeButton: true, disableTimeOut: true, enableHtml: true});
            return;
        }

        const error = this.validatePriceSides(zone);

        if (error) {
            this._toastr.error(error);
            return;
        }

        const orders = zone.items;

        const cbArg = {
            orderToCopy: zone.title,
            orders,
            limitPrice: zone.limitPrice
        } as RegroupedOrders;

        this._config.copyCallback([cbArg]);
    }

    /**
     * Validates if limit prices, provided by user match the trade side. That is: debit trade must have debit (negative) limit px,
     * credit trade - credit (positive) limit px.
     *
     * @returns Error message if validation fails, or nothing is validation passes
     *
     * @private
     */
    private validatePriceSides(zone: DropZone) {

        if (!isValidNumber(zone.limitPrice, true)) {
            return;
        }

        const chunkMarketPrice = this.getChunkMarketPrice(zone, true);

        const sign = Math.sign(chunkMarketPrice);

        const limitSign = Math.sign(zone.limitPrice);

        if (sign === limitSign) {
            return;
        }

        const msg = `The Limit Price and Trade Side Don't Match For this Order: ${zone.title}. 
            Make Sure Debit Trades Have Debit Limit Price and Credit Trades - Credit Limit Price`;

        return msg;
    }

    copyAllOrders() {

        if (this.dropZones.length === 0) {
            this._toastr.error("No orders to copy");
            return;
        }

        if (!isVoid(this._reverseTicker)) {
            const optionTicker = parseOptionTicker(this._reverseTicker);
            const tickerDisplayName = makeOptionTickerDisplayName(optionTicker);
            this._toastr.error(`One Of The Legs Among Regrouped Is A Reversing Trade! (${tickerDisplayName}) <br> Reversing Orders Will Be Rejected by The Broker. Please, split trade quantity so that one order close existing positions, and second open desired one`, 'Attention',
                {closeButton: true, disableTimeOut: true, enableHtml: true});
            return;
        }

        const error = this.validate();

        if (!isVoid(error)) {
            this._toastr.error(error);
            return;
        }

        const allOrders = this.dropZones.map(dz => {

            // const orders = dz.items.map(y => y.order);
            const orders = dz.items;

            return {
                orderToCopy: dz.title,
                orders,
                limitPrice: dz.limitPrice
            } as RegroupedOrders;
        });

        this._config.copyCallback(allOrders);
    }

    @DetectMethodChanges()
    onSplitQtyModeChanged($event: any) {

        if (this.dropZones.length === 0) {
            return;
        }

        const title = this.dropZones[0].title;

        this.dropZones
            .slice()
            .forEach(dz => this.removeZone(dz));

        const dz = new DropZone();
        dz.title = title;
        this.dropZones.push(dz);
    }

    @DetectMethodChanges({isAsync: true})
    async onSettledQtyChanged(value: number, order: DropZoneOrder) {

        const orderSideMultiplier = Math.sign(order.order.qty);

        if (Math.sign(value) !== orderSideMultiplier) {
            this._toastr.error("The quantity you entered doesn't match order's action");
            return;
        }

        const prev = Math.abs(order.qty || 0) * orderSideMultiplier;

        const curr = Math.abs(value || 0) * orderSideMultiplier;

        const delta = curr - prev;

        const adjusted = order.adjustSettledQty(delta);

        if (!adjusted) {
            this._toastr
                .error('Cannot adjust order qty. Please, check that total settled quantity matches original order\'s quantity');
        } else {
            order.qty = value;
        }

        await this.relabelLegs()
    }

    private validate(): string {

        // Rule 1: one order should not contain 2 same tickers
        for (const zone of this.dropZones) {
            const duplicates = Enumerable.from(zone.items)
                .groupBy(x => x.ticker)
                .count(x => x.count() > 1);

            if (duplicates > 0) {
                return `Same contract legs are not allowed within one order (${zone.title})`;
            }
        }


        // Rule 2: split orders qty must match
        if (this.splitQty) {
            const summarized = Enumerable.from(this.dropZones)
                .selectMany(x => x.items)
                .groupBy(x => x.ticker)
                .select(x => {
                    return {
                        ticker: x.key(),
                        qty: x.sum(y => y.qty)
                    }
                }).toArray();

            const originals = this.orderGroups.flatMap(x => x.orders);
            for (const summarizedElement of summarized) {
                const match = originals.find(x => x.ticker === summarizedElement.ticker);
                if (isVoid(match)) {
                    continue;
                }
                if (match.qty !== summarizedElement.qty) {
                    return "Regrouped orders' quantity does not match original orders' quantity;";
                }
            }
        }

        // side of prices should match
        for (const zone of this.dropZones) {

            const error = this.validatePriceSides(zone);

            if (!isVoid(error)) {
                return error;
            }
        }
    }

    getPriceInputStyle(px: number): string {
        let style = 'text-align: center;';
        if (px > 0) {
            style += 'color: green';
        } else if (px < 0) {
            style += 'color: red';
        }
        return style;
    }

    async relabelLegs() {

        this._reverseTicker = undefined;

        try {



            let positions = [];

            if (this._config.context === 'hg') {
                const hgPositions = await this._hedgePositionsService.getHedgePositions(this._config.portfolio);
                positions = hgPositions.map(x => {
                    return {
                        ticker: x.ticker,
                        hedgeName: x.label,
                        qty: x.qty
                    }
                });
            } else if (this._config.context ===  'apg') {
                const apgPositions =  await this._apgDataService.getPortfolioPositions(this._config.portfolio);
                positions = apgPositions.flatMap(x => x)
                    .map(x => {
                        return {
                            ticker: x.ticker,
                            hedgeName: x.strategy,
                            qty: x.qty
                        }
                    });
            } else {
                return;
            }


            this.dropZones.flatMap(x => x.items)
                .forEach((item) => {

                    const predicate = this._config.context === 'apg'
                        ? (x) => x.ticker === item.ticker
                        : (x) => x.ticker === item.ticker && x.hedgeName === item.group?.title

                    let existing: { ticker: string, qty: number } = positions
                        .find(predicate);

                    let action = item.action;

                    if (isVoid(existing)) {

                        const newPos = { ticker: item.ticker, hedgeName: item.group?.title, qty: item.qty };
                        positions.push(newPos);
                        action = item.qty > 0 ? 'Buy' : 'Sell';
                        action = `${action} To Open`;
                        item.ownAction = action;

                    } else {
                        const beforeQty = existing.qty;
                        const newQty = beforeQty + item.qty;

                        if (newQty === 0) {
                            if (beforeQty > 0) {
                                action = 'Sell To Close';
                            } else if (beforeQty < 0) {
                                action = 'Buy To Close';
                            }
                        } else {
                            if (Math.sign(beforeQty) === 0) {
                                if (newQty > 0) {
                                    action = 'Buy To Open';
                                } else if (newQty < 0) {
                                    action = 'Sell To Open';
                                }
                            } else {
                                if (Math.sign(newQty) === Math.sign(beforeQty)) {
                                    if (newQty > 0) {
                                        if (newQty > beforeQty) {
                                            action = 'Buy More';
                                        } else if (newQty < beforeQty) {
                                            action = 'Sell To Reduce';
                                        }
                                    } else if (newQty < 0) {
                                        if (newQty < beforeQty) {
                                            action = 'Sell More';
                                        } else if (newQty > beforeQty) {
                                            action = 'Buy To Reduce';
                                        }
                                    }
                                } else {
                                    if (newQty > 0) {
                                        action = 'Buy To Reverse';
                                    } else if (newQty < 0) {
                                        action = 'Sell To Reverse';
                                    }

                                    this._reverseTicker = item.ticker;

                                }
                            }
                        }

                        item.ownAction = action;
                        existing.qty = newQty;
                    }
                });

        } catch (e) {
            console.error(e);
        }
    }

    dropZoneHasLimitPrice(zone: DropZone) {
        return isValidNumber(zone.limitPrice, true);
    }

    @DetectMethodChanges()
    onLimitPriceChanged(args: DxValueChanged<number>, zone: DropZone) {
    }


    // Original Prices
    getOriginalMarketPrice() : number {

        const hcf = this.getOriginalHcf();

        const prices = this._config.orders.map(og => {
            const prices = og.orders
                .map(y => Math.abs(y.price) * y.qty * -1);

            const sum = prices
                .reduce((p, c) => p + c, 0);
            return sum;
        });

        const total = prices.reduce((p, c) => p + c);

        const adjusted = total / hcf;

        return adjusted;
    }

    getOriginalLedgerPrice() : number {
        const marketPrice = this.getOriginalMarketPrice();

        const totalHcf = this.getOriginalHcf();

        const ratio = totalHcf / this.portfolioDefaultQty;

        return marketPrice * ratio;
    }

    getOriginalHcf() {
        const qtties = this._config.orders.flatMap(og => {
            const qty = og.orders
                .map(y => y.qty);
            return qty;
        });

        const hcf = findHCF(qtties);

        return hcf;
    }

    // New Prices
    getNewMarketPrice() : number {
        const remainingOrders = this.orderGroups
            .flatMap(og => og.orders)
            .filter(x => {
                if (this.splitQty) {
                    return  !x.isSettled;
                } else {
                    return !(x as any).moved;
                }
            });

        let remainingSum = 0;

        if (remainingOrders.length > 0) {
            const leftOverQtties = remainingOrders
                .map(x => x.leftOverQty);

            const hcf = findHCF(leftOverQtties);

            const prices = remainingOrders.map(x =>  {
                const price = Math.abs(x.price) * x.leftOverQty * -1;
                return price;
            })

            const sum = prices.reduce((p, c) => p + c, 0);

            remainingSum = sum / hcf;
        }

        const chunkPrices = this.dropZones.map(x => this.getChunkMarketPrice(x) || 0);

        const sumOfChunks = chunkPrices.reduce((p, c) => p + c, 0) || 0;

        const total = remainingSum + sumOfChunks;

        return total;
    }

    getNewLedgerPrice() : number {

        const remainingOrders = this.orderGroups
            .flatMap(og => og.orders)
            .filter(x => {
                if (this.splitQty) {
                    return  !x.isSettled;
                } else {
                    return !(x as any).moved;
                }
            });

        let remainingSum = 0;

        if (remainingOrders.length > 0) {
            const leftOverQtties = remainingOrders
                .map(x => x.leftOverQty);

            const hcf = findHCF(leftOverQtties);

            const prices = remainingOrders.map(x =>  {
                const price = Math.abs(x.price) * x.leftOverQty * -1;
                return price;
            });

            const ratio = hcf / this.portfolioDefaultQty;

            const sum = prices.reduce((p, c) => p + c, 0);

            remainingSum = (sum / hcf) * ratio ;
        }

        const chunkPrices = this.dropZones.map(x => this.getChunkLedgerPrice(x) || 0);

        const sumOfChunks = chunkPrices.reduce((p, c) => p + c, 0) || 0;

        const total = remainingSum + sumOfChunks;

        return total;
    }

    // Chunks
    getChunkHcf(chunk: DropZone): number {
        const numbers = chunk.items.map(x => x.qty);
        const hcf = findHCF(numbers);
        return hcf;
    }

    getChunkLedgerPrice(zone: DropZone, realPrice?: boolean) {

        if (isVoid(zone.items)) {
            return undefined;
        }

        const mp = this.getChunkMarketPrice(zone, realPrice);

        const hcf = this.getChunkHcf(zone);

        const ratio = hcf / this.portfolioDefaultQty;

        return mp * ratio;
    }

    getChunkMarketPrice(zone: DropZone, realPrice?: boolean) {

        if (isVoid(zone.items)) {
            return undefined;
        }

        const totalHcf = this.getChunkHcf(zone);

        const prices = zone.items.map(x => {
            const px = Math.abs(x.price) * x.qty * -1;
            return px;
        });

        const total = prices.reduce((p, c) => p + c, 0);

        const adjusted = total / totalHcf;

        if (!realPrice) {
            if (isValidNumber(zone.limitPrice, true)) {
                return zone.limitPrice;
            }
        }
        return adjusted;
    }

    anyDropZoneHasLimitPrice() {
        return this.dropZones.some(x => isValidNumber(x.limitPrice, true));
    }
}