import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    Optional, ViewChild
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {
    AccessControlService,
    CopyToEtsDestinationId,
    CopyToTOSDesktopDestinationId, CopyToTOSWebDestinationId
} from 'projects/shared-components/access-control-service.class';
import {ApplicationSettingsService} from 'projects/shared-components/app-settings/application-settings.service';
import {LocationService} from 'projects/shared-components/location.service';
import {ApgChecklistService} from '../popup/checklist/apg-checklist.service';
import {CopyOrdersToService, CopyToDestination, OrderToCopy} from '../services/copy-orders-to.service';
import {PositionsAfterStateService} from '../services/positions-after-state.service';
import {PositionsStateService} from '../services/positions-before-state.service';
import {CashFlowStrategy} from 'projects/shared-components/adjustment-control-panel/cash-flow-strategy';
import {
    balanceStatePositions,
    delay,
    DetectMethodChanges,
    DetectSetterChanges,
    DxValueChanged,
    findHCF,
    getChangingPositionsForZeroAdjustmentCase,
    getPriceClass,
    isVoid
} from 'projects/shared-components/utils';
import {isUndefined} from 'util';
import {ChecklistPopupModel} from '../popup/checklist/ChecklistPopupModel';
import {CashFlowAdjustment} from '../model/CashFlowAdjustment';
import {CashFlowStrategyRole} from "../../shell-communication/shell-dto-protocol";
import {MessageBusService} from "../../message-bus.service";
import {Subscription} from "rxjs";
import {AdjustmentPricingGridComponent} from "../adjustment-pricing-grid.component";
import {OrdersRegroupDialogComponent} from "../../orders-regroup-dialog/orders-regroup-dialog.component";
import {OrdersGroupToRegroup} from "../../orders-regroup-dialog/OrdersGroupToRegroup";
import {OrderToRegroupWrapper} from "../../orders-regroup-dialog/OrderToRegroupWrapper";
import {RegroupOrdersConfig} from "../../orders-regroup-dialog/RegroupOrdersConfig";
import {RegroupedOrders} from "../../orders-regroup-dialog/RegroupedOrders";
import {RegroupedOrdersSubstitute} from "../../orders-regroup-dialog/RegroupedOrdersSubstitute";
import {ApgDataService} from "../services/apg-data.service";
import {ApgPortfolio} from "../model/ApgPortfolio";

@Component({
    selector: 'ets-adjustment-solution-popup',
    templateUrl: './adjustment-solution-popup.component.html',
    styleUrls: [
        './adjustment-solution-popup.component.scss',
        '../adjustment-grid-common-style.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdjustmentSolutionPopupComponent implements OnInit, AfterViewInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _accessControlService: AccessControlService,
        private readonly _checklistService: ApgChecklistService,
        private readonly _applicationSettings: ApplicationSettingsService,
        private readonly _toastr: ToastrService,
        @Optional() private readonly _apgDataService: ApgDataService,
        @Optional() private readonly _copyOrdersToService?: CopyOrdersToService,
        @Optional() private readonly _locationService?: LocationService,
        @Optional() private readonly _beforeStateService?: PositionsStateService,
        @Optional() private readonly _positionsAfterStateService?: PositionsAfterStateService,
        @Optional() private readonly _messageBus?: MessageBusService,
    ) {
        this.checklistPopup = new ChecklistPopupModel(
            _changeDetector,
            () => this.onChecklistConfirmed(),
            _checklistService
        );
    }

    private _nextExpirations: { [ix: string]: CashFlowAdjustment } = {};

    private _previousExpirations: { [ix: string]: CashFlowAdjustment } = {};

    private _quoteUpdatesPending = false;

    private  _quotesSubscription: Subscription;

    private _refreshTask: number;

    @Input()
    readOnly = false;

    @Input() root: AdjustmentPricingGridComponent;

    @ViewChild(OrdersRegroupDialogComponent)
    ordersReorderDialog: OrdersRegroupDialogComponent;

    get isZonesGrid():boolean {
        return this.pricings.some(x => !isVoid(x.zonesGridStrikeDelta));
    }

    isVisible: boolean;

    readonly checklistPopup: ChecklistPopupModel;

    isChecklistConfirmed = false;

    portfolio: ApgPortfolio;

    strategyType: 'single' | 'double';

    legRatios: Record<CashFlowStrategyRole, number> = {} as any;

    source: "PriceBox" | "Zones Grid";

    get height() {
        return '95vh';
    }

    get width() {
        return this.pricings.length == 2 ? '99vw' : '55vw';
    }

    get maxWidth() {
        return this.pricings.length === 2 ? '1650' : '820px';
    }

    get maxHeight() {
        return '99vh';
    }

    private _isLoading: boolean;
    get isLoading(): boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(v: boolean) {
        this._isLoading = v;
    }

    private _pricings: CashFlowAdjustment[] = [];
    get pricings(): CashFlowAdjustment[] {
        return this._pricings;
    }

    set pricings(value: CashFlowAdjustment[]) {
        this._pricings = value;
        this.fillAvailableDestinations(this._locationService);
        this.fillAvailableOrdersToCopy(value);
    }

    get qtyChangeEnabled(): boolean {
        return this.pricings.some(x => x.logicalId === '#2' && !x.isPriceToClose);
    }

    destinationList: CopyToDestination[] = [];

    selectedDestination: CopyToDestination;

    ordersList: OrderToCopy[];

    selectedOrder: OrderToCopy;

    ordersList2: OrderToCopy[];

    selectedOrder2: OrderToCopy;

    orderQty = 1;

    orderQty2 = 1;

    get containerCss() {
        const css = ['popup'];
        if (this.pricings.length >= 2) {
            css.push('double');
        }

        return css;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this._changeDetector.detach();
    }

    @DetectMethodChanges()
    show(
        portfolio: ApgPortfolio,
        pricings: CashFlowAdjustment[],
        strategyType: 'single' | 'double',
        source: 'PriceBox' | 'Zones Grid'
    ) {

        if (pricings.length === 0) {
            return;
        }

        if (['single', 'double'].indexOf(strategyType) < 0) {
            this._toastr.error('Strategy type cannot be determined');
            return;
        }

        this.source = source;

        if (this._messageBus) {

            this._quotesSubscription = this._messageBus.of('Apg.QuotesUpdated')
                .subscribe(_ => this._quoteUpdatesPending = true);

            this._refreshTask = setInterval(() => {
                if (!this._quoteUpdatesPending) {
                    return;
                }
                this._changeDetector.detectChanges();
            }, 1000) as any;
        }

        pricings.forEach(x => x.positionsVisible = true);

        this.portfolio = portfolio;
        this.strategyType = strategyType;

        if (!this.readOnly) {

            pricings.forEach((pricing, ix) => {

                let stateForCalculations = pricing.afterState;

                if (pricing.isPriceToClose) {
                    stateForCalculations = pricing.beforeState;
                }

                const qtys = stateForCalculations.map(x => Math.abs(x.qty));

                const hcf = findHCF(qtys);

                if (ix === 0) {
                    this.orderQty = hcf;
                } else {
                    this.orderQty2 = hcf;
                }

                if (pricing.logicalId === '#0') {
                    const changingPositions = getChangingPositionsForZeroAdjustmentCase(pricing.beforeState, pricing.afterState);
                    this.legRatios = this.getLegRatios(changingPositions);
                } else {
                    this.legRatios = this.getLegRatios(stateForCalculations);

                    if (pricing.logicalId === '#2' && pricing.isPriceToClose) {
                        balanceStatePositions(pricing.beforeState, pricing.afterState);
                    }

                }
            });
        }

        this.pricings = pricings;

        this.isChecklistConfirmed = false;

        this.isVisible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        clearInterval(this._refreshTask);

        if ((!isVoid(this._quotesSubscription))) {
            this._quotesSubscription.unsubscribe();
        }

        this.reset();
    }

    @DetectMethodChanges()
    reset() {
        this._pricings = [];
        this._previousExpirations = {};
        this._nextExpirations = {};
        this.destinationList = [];
        this.ordersList = [];
        this.orderQty = undefined;
        this.orderQty2 = undefined;
        this.selectedOrder = undefined;
        this.selectedOrder2 = undefined;
        this.selectedDestination = undefined;
        this.portfolio = undefined;
        this.strategyType = undefined;
        this.isChecklistConfirmed = false;
        this.isVisible = false;
    }

    @DetectMethodChanges({isAsync: true})
    async copyOrdersTo(pricing: CashFlowAdjustment) {

        if (this.readOnly) {
            return;
        }

        if (!pricing) {
            return;
        }

        if (!this.isChecklistConfirmed) {

            this.isLoading = true;

            try {

                const checklistRequired = await this.showChecklistIfNecessary(pricing);

                if (!checklistRequired) {
                    await this.copyOrdersToClipboardInternal(pricing);
                }

            } finally {
                this.isLoading = false;
            }

        }
    }

    private async showChecklistIfNecessary(pricing: CashFlowAdjustment, regrouped?: RegroupedOrders[]): Promise<boolean> {

        if (this.readOnly) {
            return false;
        }

        if (!pricing) {
            return false;
        }

        let hasChecklistAssigned = true;

        if (this.isChecklistConfirmed) {
            return false;
        }

        this.isLoading = true;

        try {

            const first = this.pricings[0];
            const second = this.pricings[1];

            const includeDrafts = this._applicationSettings.adjustmentPricingGrid.showChecklistDrafts;

            const checklistStepsFirst =
                await this._checklistService.getChecklistStepsFiltered(first, includeDrafts);

            let checklistStepsSecond = [];

            if (!isVoid(second)) {
                checklistStepsSecond =
                    await this._checklistService.getChecklistStepsFiltered(second, includeDrafts);
            }

            if (checklistStepsFirst.length === 0) {
                if (isVoid(checklistStepsSecond)) {
                    hasChecklistAssigned = false;
                }
            }

            if (!hasChecklistAssigned) {
                return false;
            }

            const checklists = [checklistStepsFirst];

            if (this.pricings.length === 2) {
                checklists.push(checklistStepsSecond);
            }

            this.checklistPopup.show(
                checklists,
                pricing,
                regrouped
            );

            return true;

        } finally {
            this.isLoading = false;
        }
    }

    private async copyOrdersToClipboardInternal(pricing: CashFlowAdjustment, regroupedOrders?: RegroupedOrders[]) {

        const portfolioId = this.portfolio?.id;

        const underlying = pricing.underlying;

        const strategy = this.strategyType === 'double'
            ? 'Calls & Puts'
            : pricing.cashFlowStrategy;

        const isSecondSlot = this.strategyType === 'double'
            && pricing.cashFlowStrategy === 'Puts';

        const slot = isSecondSlot ? 'second' : 'first';

        this._positionsAfterStateService.saveState(
            pricing,
            portfolioId,
            underlying,
            strategy,
            slot
        );

        const getSelectedOrder = (): OrderToCopy => {

            if (!isVoid(regroupedOrders)) {
                return regroupedOrders.length > 1 ? 'All' : regroupedOrders[0].orderToCopy
            }

            if (this.pricings.length === 1) {
                return this.selectedOrder;
            }

            const ix = this.pricings.indexOf(pricing);

            if (ix === 0) {
                return this.selectedOrder;
            } else if (ix === 1) {
                return this.selectedOrder2;
            }
        };

        const getOrderQty = (): number => {

            if (this.pricings.length === 1) {
                return this.orderQty;
            }

            const ix = this.pricings.indexOf(pricing);

            if (ix === 0) {
                return this.orderQty;
            } else if (ix === 1) {
                return this.orderQty2;
            }
        };

        let regroupedSubstitute: RegroupedOrdersSubstitute;

        if (!isVoid(regroupedOrders)) {
            const mainLegs = regroupedOrders.find(x => x.orderToCopy === 'Main')?.orders;
            const linkedLegs = regroupedOrders.find(x => x.orderToCopy === 'Linked #1')?.orders;
            const secondLinkedLegs = regroupedOrders.find(x => x.orderToCopy === 'Linked #2')?.orders;
            const thirdLinkedLegs = regroupedOrders.find(x => x.orderToCopy === 'Linked #3')?.orders;

            regroupedSubstitute = {
                mainLegs,
                linkedLegs,
                secondLinkedLegs,
                thirdLinkedLegs
            };
        }

        if (this.selectedDestination === 'ETS') {

            const strategy: CashFlowStrategy = this.pricings.length > 1
                ? 'Calls & Puts'
                : this.pricings[0].cashFlowStrategy;

            const adjustmentCode = this.pricings.map(x => x.adjustmentType).join('+');

            const expirationDateWithDte = this.pricings[0].expirationDatePrettyWithDaysToExpiration;
            const parts = expirationDateWithDte.split(' ');
            const expiration = parts[0];
            let dte = parts[1].replace('(', '');
            dte = dte.replace('d)', '');
            dte = dte.trim();
            const pricingOption = pricing.adjustmentType;
            const daysToExpiration = parseInt(dte);

            const selectedOrder = getSelectedOrder();
            console.assert(!isVoid(selectedOrder));

            const orderQty = getOrderQty();
            console.assert(!isVoid(orderQty));

            let result = this._copyOrdersToService.copyToETS(
                pricing,
                selectedOrder,
                orderQty,
                {
                    adjustmentCode,
                    strategy,
                    daysToExpiration,
                    expiration,
                    pricingOption
                },
                regroupedSubstitute
            );

            if (!result) {

                this._toastr.error('"Copy To ETS" operation completed with errors');

            } else {

                this._toastr.success(`Orders for "${this.selectedDestination}" have been copied to clipboard`);

            }

        }
        else if (this.selectedDestination === 'TOS Desktop') {

            const selectedOrder = getSelectedOrder();

            console.assert(!isVoid(selectedOrder));

            const orderQty = getOrderQty();

            console.assert(!isVoid(orderQty));

            let result = await this._copyOrdersToService.copyToTOSDesktop(
                pricing,
                selectedOrder,
                orderQty,
                regroupedSubstitute
            );

            if (!result) {

                this._toastr.error('"Copy To TOS Desktop" operation completed with errors');

            } else {

                this._toastr.success(`Orders for "${this.selectedDestination}" have been copied to clipboard`);

            }

        }
        else if (this.selectedDestination === 'TOS Web') {

            const selectedOrder = getSelectedOrder();
            console.assert(!isVoid(selectedOrder));

            const orderQty = getOrderQty();
            console.assert(!isVoid(orderQty));

            let result = await this._copyOrdersToService.copyToTOSWeb(
                pricing,
                selectedOrder,
                orderQty
            );

            if (!result) {

                this._toastr.error('"Copy To TOS Web" operation completed with errors');

            } else {

                this._toastr.success(`Orders for "${this.selectedDestination}" have been copied to clipboard`);

            }

        }
        else {

            this._toastr.error(`Provided destination (${this.selectedDestination}) not supported`);

        }
    }

    getPreviousExpiration(
        adjustment: CashFlowAdjustment
    ): CashFlowAdjustment {

        if (this.readOnly) {
            return undefined;
        }

        if (isVoid(adjustment)) {
            return undefined;
        }

        let previousExp = this._previousExpirations[adjustment.uniqueAdjustmentSpecificationId]

        if (isUndefined(previousExp)) {

            if (adjustment.adjustmentTypeObj) {

                const currIx = adjustment.adjustmentTypeObj.adjustments
                    .findIndex(x => x.expiration === adjustment.expiration);

                const prevIx = currIx - 1;

                if (prevIx < 0 || prevIx >= adjustment.adjustmentTypeObj.adjustments.length) {
                    previousExp = null;
                } else {
                    previousExp = adjustment.adjustmentTypeObj.adjustments[prevIx];
                }

                this._previousExpirations[adjustment.uniqueAdjustmentSpecificationId] = previousExp;
            }
        }

        return previousExp;
    }

    getNextExpiration(
        pricing: CashFlowAdjustment
    ): CashFlowAdjustment {

        if (this.readOnly) {
            return undefined;
        }

        if (isVoid(pricing)) {
            return undefined;
        }

        let nextExp = this._nextExpirations[pricing.uniqueAdjustmentSpecificationId]

        if (isUndefined(nextExp)) {

            if (pricing.adjustmentTypeObj) {

                const currIx = pricing.adjustmentTypeObj.adjustments
                    .findIndex(x => x.expiration === pricing.expiration);

                const nextIx = currIx + 1;

                if (nextIx < 0 || nextIx >= pricing.adjustmentTypeObj.adjustments.length) {
                    nextExp = undefined;
                } else {
                    nextExp = pricing.adjustmentTypeObj.adjustments[nextIx];
                }

                this._nextExpirations[pricing.uniqueAdjustmentSpecificationId] = nextExp;
            }

        }
        return nextExp;
    }

    async movePreviousExpiration(
        pricing: CashFlowAdjustment
    ) {
        const previous = this.getPreviousExpiration(pricing);
        if (previous) {
            await this.moveToExpiration(previous, pricing);
        }
    }

    async moveNextExpiration(
        pricing: CashFlowAdjustment
    ) {
        const next = this.getNextExpiration(pricing);
        if (next) {
            await this.moveToExpiration(next, pricing);
        }
    }

    private async moveToExpiration(
        previous: CashFlowAdjustment,
        current: CashFlowAdjustment
    ) {

        if (this.readOnly) {
            return;
        }

        this.isChecklistConfirmed = false;

        if (!previous.adjustmentTypeObj) {
            return;
        }

        const ix = previous.adjustmentTypeObj.adjustments
            .findIndex(x => x.expiration === previous.expiration);

        if (ix < 0) {
            return;
        }

        this.isLoading = true;

        await delay(250);

        try {

            const ix = this.pricings.indexOf(current);

            console.assert(ix >= 0, 'move to next expiration - index found');

            if (ix >= 0) {
                const slice = this.pricings.slice();

                slice[ix] = previous;

                this.pricings = slice;
            }

            delete this._previousExpirations[previous.uniqueAdjustmentSpecificationId];
            delete this._nextExpirations[previous.uniqueAdjustmentSpecificationId];

        } finally {

            await delay(500);
            this.isLoading = false;

        }
    }

    getPriceClass(exp: CashFlowAdjustment) {
        return getPriceClass(exp);
    }

    hasLinkedOrder(pricing: CashFlowAdjustment): boolean {

        if (!pricing) {
            return false;
        }

        return pricing.linkedLegs && pricing.linkedLegs.length > 0;
    }

    hasSecondLinkedOrder(pricing: CashFlowAdjustment): boolean {

        if (!pricing) {
            return false;
        }

        return pricing.secondLinkedLegs && pricing.secondLinkedLegs.length > 0;
    }

    hasThirdLinkedOrder(pricing: CashFlowAdjustment): boolean {

        if (!pricing) {
            return false;
        }

        return pricing.thirdLinkedLegs && pricing.thirdLinkedLegs.length > 0;
    }

    private fillAvailableDestinations(_locationService: LocationService) {

        if (this.readOnly) {
            return;
        }

        const destinations: CopyToDestination[] = [];

        if (this._accessControlService.isSecureElementAvailable(CopyToEtsDestinationId)) {
            destinations.push('ETS');
        }

        if (this._accessControlService.isSecureElementAvailable(CopyToTOSDesktopDestinationId)) {
            destinations.push('TOS Desktop');
        }

        if (this._accessControlService.isSecureElementAvailable(CopyToTOSWebDestinationId)) {
            if (!this.pricings.some(x => x.underlying === 'SPX' || x.underlying === 'XSP')) {
                destinations.push('TOS Web');
            }
        }

        let targetDestination = this.selectedDestination;

        if (isVoid(targetDestination)) {

            const lastUsedDestination = this._copyOrdersToService.getLastUsedDestination();

            if (lastUsedDestination) {
                if (destinations.indexOf(lastUsedDestination) >= 0) {
                    targetDestination = lastUsedDestination;
                }
            }
        }

        this.destinationList = destinations;

        if (this.destinationList.indexOf(targetDestination) >= 0) {
            this.selectedDestination = targetDestination;
        } else {
            if (this.destinationList.length === 1) {
                this.selectedDestination = this.destinationList[0];
            } else {
                if (this.destinationList.indexOf('ETS') >= 0) {
                    this.selectedDestination = 'ETS';
                }
            }
        }
    }

    private fillAvailableOrdersToCopy(value?: CashFlowAdjustment[]) {

        if (this.readOnly) {
            return;
        }

        if (isVoid(value)) {

            value = this.pricings;

            if (isVoid(value)) {
                return;
            }

        }

        value.forEach((pricing, ix) => {

            const isFirstPricing = ix === 0;

            const main = pricing.mainLegs;
            const linked = pricing.linkedLegs;
            const linked2 = pricing.secondLinkedLegs;
            const linked3 = pricing.thirdLinkedLegs;

            const ordersList: OrderToCopy[] = [];

            if (!isVoid(this.selectedDestination)) {

                if (this.selectedDestination !== 'TOS Web') {
                    ordersList.push('All');
                }

                if (!isVoid(main)) {
                    ordersList.push('Main');
                }

                if (!isVoid(linked)) {
                    ordersList.push('Linked #1');
                }

                if (!isVoid(linked2)) {
                    ordersList.push('Linked #2');
                }

                if (!isVoid(linked3)) {
                    ordersList.push('Linked #3');
                }
            }

            if (isFirstPricing) {
                this.ordersList = ordersList;
            } else {
                this.ordersList2 = ordersList;
            }

            const setSelectedOrder = (x: OrderToCopy) => {
                if (isFirstPricing) {
                    this.selectedOrder = x;
                } else {
                    this.selectedOrder2 = x;
                }
            }

            const targetOrderToCopy = isFirstPricing
                ? this.selectedOrder
                : this.selectedOrder2;

            if (!isVoid(this.selectedDestination)) {

                if (ordersList.indexOf(targetOrderToCopy) >= 0) {

                    setSelectedOrder(targetOrderToCopy);

                } else {

                    if (this.selectedDestination === 'TOS Web') {

                        setSelectedOrder('Main');

                    } else {

                        setSelectedOrder('All');

                    }
                }

            } else {

                setSelectedOrder(undefined);

            }
        });

    }

    @DetectMethodChanges({isAsync: true})
    async onDestinationChanged(ev: DxValueChanged<CopyToDestination>) {

        if (this.readOnly) {
            return;
        }

        this.fillAvailableOrdersToCopy();
        await this._copyOrdersToService.saveLastUsedDestination(ev.value);
        this.isChecklistConfirmed = false;

    }

    async onChecklistConfirmed(): Promise<void> {

        this.isChecklistConfirmed = false;

        const pricing = this.checklistPopup.selectedPricing;
        const regrouped = this.checklistPopup.regroupedOrders;

        if (isVoid(pricing)) {
            this._toastr.error('Cannot copy orders: solution not selected');
            return;
        }

        await this.copyOrdersToClipboardInternal(pricing, regrouped);
    }

    @DetectMethodChanges()
    onOrderQtyChanged(ev: DxValueChanged<number>, slot: 0 | 1) {
    }

    private getLegRatios(positions: {role: CashFlowStrategyRole, qty: number}[]): Record<CashFlowStrategyRole, number> {
        const record: Record<CashFlowStrategyRole, number> = {} as any;
        const hcf = findHCF(positions.map(x => x.qty));
        positions.forEach(pos => {
            record[pos.role] = Math.abs(pos.qty / hcf);
        });
        return record;
    }

    @DetectMethodChanges()
    notifyChangeDetector() {
    }

    getDestinationStrike(pricing: CashFlowAdjustment) {
        return parseInt(pricing?.destinationStrike) + (pricing.zonesGridStrikeDelta || 0)
    }

    getCurrentStrike(pricing: CashFlowAdjustment) {
        return parseInt(pricing?.initialStrike) + (pricing?.zonesGridStrikeDelta || 0);
    }

    regroupOrders(pricing: CashFlowAdjustment) {

        const ordersGroupToReorder : OrdersGroupToRegroup[] = []

        if (!isVoid(pricing.mainLegs)) {
            const grp = new OrdersGroupToRegroup(
                "Main",
                pricing.mainLegs.map(x => new OrderToRegroupWrapper(x))
            );
            ordersGroupToReorder.push(grp);
        }

        if (!isVoid(pricing.linkedLegs)) {
            const grp = new OrdersGroupToRegroup(
                "Linked #1",
                pricing.linkedLegs.map(x => new OrderToRegroupWrapper(x))
            );
            ordersGroupToReorder.push(grp);
        }

        if (!isVoid(pricing.secondLinkedLegs)) {
            const grp = new OrdersGroupToRegroup(
                "Linked #2",
                pricing.secondLinkedLegs.map(x => new OrderToRegroupWrapper(x))
            );
            ordersGroupToReorder.push(grp);
        }

        if (!isVoid(pricing.thirdLinkedLegs)) {
            const grp = new OrdersGroupToRegroup(
                "Linked #3",
                pricing.thirdLinkedLegs.map(x => new OrderToRegroupWrapper(x))
            );
            ordersGroupToReorder.push(grp);
        }

        const copyOrdersCallback = async (args: RegroupedOrders[]) => {

            const chkListShown = await this.showChecklistIfNecessary(pricing, args);

            if (!chkListShown) {
                await this.copyOrdersToClipboardInternal(pricing, args);
            }
        };

        const config: RegroupOrdersConfig = {
            orders: ordersGroupToReorder,
            copyCallback: copyOrdersCallback,
            portfolio: this.portfolio,
            context: 'apg'
        };

        this.ordersReorderDialog.show(config);
    }
}
