<dx-popup (onHidden)="this.onHidden()"
          [(visible)]="this.visible"
          [closeOnOutsideClick]="false"
          [dragEnabled]="true"
          [height]="525"
          [resizeEnabled]="true"
          [width]="880"
          title="Re-group Orders Dialog"
>
  <div #wrapper *dxTemplate="let data of 'content'" class="wrapper">
    <div class="content">
      
      <div class="orders side">
        <div class="orders-section">
          <dx-scroll-view>
            <ng-container *ngFor="let orderGroup of this.orderGroups">
              <fieldset class="order-group">
                <legend [style.color]="orderGroup.orders[0]?.color" class="legend">{{ orderGroup.title }}</legend>
                <div class="orders-list">
                  <ng-container *ngFor="let order of orderGroup.orders">
                    <ng-container *ngTemplateOutlet="orderTpl; context: {order: order, group: orderGroup}">
                    </ng-container>
                  </ng-container>
                </div>
              </fieldset>
            </ng-container>
          </dx-scroll-view>
        </div>
        
        <div class="prices-section">
          <div class="price box original-ledger">
            <div class="label">
              Original Ledger Px.
            </div>
            <div class="value">
              <dx-number-box #originalTotalLedgerPx
                             [readOnly]="true"
                             [value]="this.getOriginalLedgerPrice()"
                             format="$#0.00"
                             [inputAttr]="{style: this.getPriceInputStyle(originalTotalLedgerPx.value)}"
              ></dx-number-box>
            </div>
          </div>
          
          <div class="price box market">
            <div class="label">
              Market Px.
            </div>
            <div class="value">
              <dx-number-box #originalTotalMarketPx
                             [readOnly]="true"
                             [value]="this.getOriginalMarketPrice()"
                             format="$#0.00"
                             [disabled]="this.anyDropZoneHasLimitPrice()"
                             [inputAttr]="{style: this.getPriceInputStyle(originalTotalMarketPx.value)}"
              ></dx-number-box>
            </div>
          </div>
          
          <div class="price box new-ledger">
            <div class="label">
              New Ledger Px.
            </div>
            <div class="value">
              <dx-number-box #newTotalLedgerPx
                             [readOnly]="true"
                             [value]="this.getNewLedgerPrice()"
                             format="$#0.00"
                             [inputAttr]="{style: this.getPriceInputStyle(newTotalLedgerPx.value)}"
              ></dx-number-box>
            </div>
          </div>
        </div>
      </div>
      
      <div class="drop-zones side">
        <div class="zones-list">
          <dx-scroll-view>
            <fieldset (dragover)="this.onDragOver($event)" (drop)="this.onDrop($event, zone)"
                      *ngFor="let zone of this.dropZones"
                      class="zone"
            >
              <legend class="legend">{{ this.getOrderTitle(zone) }}</legend>
              
              <div class="zone-items">
                
                <ng-container *ngIf="zone.items.length === 0">
                  <div class="no-data">DRAG & DROP HERE</div>
                </ng-container>
                
                <ng-container *ngIf="zone.items.length > 0">
                  <div *ngFor="let order of zone.items">
                    <ng-container *ngTemplateOutlet="orderTpl; context: {order: order, reordered: true}">
                    </ng-container>
                  </div>
                </ng-container>
              
              </div>
              
              <div [class.empty]="this.zone.items.length === 0" class="control-buttons">
                
                <div class="remove-zone">
                  <div class="label">&nbsp;</div>
                  <div class="value">
                    <dx-button (onClick)="this.removeZone(zone)" text="Remove Order" width="100%"></dx-button>
                  </div>
                </div>
                
                <div class="prices">
                  <div class="price ledger">
                    <div class="label">
                      Ledger Px.
                    </div>
                    <div class="value">
                      <dx-number-box #chunkLedgerPx
                                     [value]="this.getChunkLedgerPrice(zone)"
                                     format="$#0.00"
                                     [inputAttr]="{style: this.getPriceInputStyle(chunkLedgerPx.value)}"
                                     [readOnly]="true"
                      ></dx-number-box>
                    </div>
                  </div>
                  <div class="price market">
                    <div class="label">
                      Market Px.
                    </div>
                    <div class="value">
                      <dx-number-box #chunkMarketPx
                                     [value]="this.getChunkMarketPrice(zone, true)" format="$#0.00"
                                     [inputAttr]="{style: this.getPriceInputStyle(chunkMarketPx.value)}"
                                     [readOnly]="true"
                      ></dx-number-box>
                    </div>
                  </div>
                  <div class="price limit">
                    <div class="label">
                      Limit Px.
                    </div>
                    <div class="value">
                      
                      <dx-number-box #chunkLimitPx
                                     [(value)]="zone.limitPrice"
                                     (onValueChanged)="this.onLimitPriceChanged($event, zone)"
                                     [showClearButton]="true"
                                     format="$#0.00"
                                     [inputAttr]="{style: this.getPriceInputStyle(chunkLimitPx.value)}"
                      ></dx-number-box>
                      
                    </div>
                  </div>
                </div>
                
                <div class="copy-orders">
                  <dx-button (onClick)="this.copySingleOrder(zone)" text="Copy Order" width="100%"></dx-button>
                </div>
              </div>
              
              <ng-container *ngIf="this.dropZoneHasLimitPrice(zone)">
                <div [class.empty]="this.zone.items.length === 0" class="control-buttons helper-row">
                  
                  <div class="remove-zone">
                    <div [style.visibility]="'hidden'" class="value">
                      <dx-button text="Remove Order" width="100%"></dx-button>
                    </div>
                  </div>
                  
                  <div class="prices">
                    
                    <div class="price ledger">
                      <div class="value" [style.padding-right.px]="5">
                        <dx-number-box #chunkLedgerPxOld
                                       [value]="this.getChunkLedgerPrice(zone, true)"
                                       [disabled]="true"
                                       [readOnly]="true"
                                       [inputAttr]="{style: this.getPriceInputStyle(chunkLedgerPxOld.value)}"
                                       format="$#0.00"
                        ></dx-number-box>
                      </div>
                    </div>
                    
                    <div class="price market">
                      <div [style.visibility]="'hidden'" class="value">
                        <dx-number-box [value]="this.getChunkMarketPrice(zone)"></dx-number-box>
                      </div>
                    </div>
                    
                    <div class="price limit">
                      <div [style.visibility]="'hidden'" class="value">
                        <dx-number-box [value]="zone.limitPrice"></dx-number-box>
                      </div>
                    </div>
                  </div>
                  
                  <div class="copy-orders">
                    <div [style.visibility]="'hidden'" class="value">
                      <dx-button text="Copy Order" width="100%"></dx-button>
                    </div>
                  </div>
                </div>
              </ng-container>
            
            </fieldset>
          </dx-scroll-view>
        </div>
        
        <hr class="separator">
        
        <div class="buttons">
          <div class="add-zone btn">
            <dx-button (onClick)="this.addDropZone()" text="Add Linked Order" width="100%">
            </dx-button>
          </div>
          
          <div class="copy btn">
            <dx-button (onClick)="this.copyAllOrders()" text="Copy All Orders" width="100%">
            </dx-button>
          </div>
          
          <div class="split btn">
            <dx-check-box (onValueChanged)="this.onSplitQtyModeChanged($event)" [(value)]="this.splitQty"
                          text="'Split Qty' Mode">
            </dx-check-box>
          </div>
        
        </div>
      
      </div>
    
    </div>
    <dx-load-panel [position]="{my: 'center', at:'center', of: wrapper}"></dx-load-panel>
  </div>

</dx-popup>

<ng-template #orderTpl let-order="order" let-reordered="reordered">
  <div (dragstart)=" !reordered ?  this.onDragStart($event, order) : null"
       [class.moved]="order.isSettled"
       [class.reordered]="reordered"
       [draggable]="!reordered"
       class="draggable"
  >
    <div class="handle col">
      <i class="fas fa-grip-vertical"></i>
    </div>
    
    <div [ngClass]="this.getActionClass(order, false)" [style.color]="order.color" class="action col">
      {{ reordered ? order.ownAction : order.action }}
    </div>
    
    <div [class.split]="this.splitQty" [ngClass]="this.getQtyClass(order)" [style.color]="order.color" class="qty col">
      
      <ng-container *ngIf="reordered">
        <ng-container *ngIf="this.splitQty">
          <dx-number-box (valueChange)="this.onSettledQtyChanged($event, order)"
                         [inputAttr]="{style: 'text-align: center;'}"
                         [value]="order.qty">
          </dx-number-box>
        </ng-container>
        <ng-container *ngIf="!this.splitQty">
          {{ order.qty }}
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="!reordered">
        <ng-container *ngIf="!this.splitQty">
          {{ order.qty }}
        </ng-container>
        <ng-container *ngIf="this.splitQty">
          {{ order.leftOverQty }} / {{ order.qty }}
        </ng-container>
      </ng-container>
    </div>
    
    <div [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color" class="underlying col">
      {{ order.underlying }}
    </div>
    
    <div [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color" class="type col">
      {{ order.type }}
    </div>
    
    <div [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color" class="expiration col">
      {{ order.expiration | etsFriendlyDatePipe:'dte' }}
    </div>
    
    <div [ngClass]="this.getPositionRoleClass(order)" [style.color]="order.color" class="strike col">
      {{ order.strike }}
    </div>
    
    <div (click)="this.removeOrder(order)" *ngIf="reordered" class="remove col">
      <i class="fa fa-times-circle"></i>
    </div>
  
  </div>
</ng-template>