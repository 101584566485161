<div class="root">
  
  <div class="row expiration">
    <dx-select-box
      [(value)]="this.selectedExpiration"
      [items]="this.expirationList"
      [showClearButton]="true"
      displayExpr="dateWithDaysToExpiration"
      width="100%"
      placeholder="Change Expiration..."
    >
      <div *dxTemplate="let data of 'item'">
                <span [style.color]="(data?.expirationTicker === '@SPX' ? 'yellow' : '')">
                   {{ data?.dateWithDaysToExpiration }}
                </span>
      </div>
    </dx-select-box>
  </div>
  
  <div class="row buy-sell">
    <div class="btn buy"
         [class.hot]="this.side === 'Long'"
         (click)="this.setSide('Long')"
    >
      Long
    </div>
    <div class="btn sell"
         [class.hot]="this.side == 'Short'"
         (click)="this.setSide('Short')"
    >
      Short
    </div>
  </div>
  
  <div class="row input">
    <dx-number-box [(value)]="this.qty"
                   [showClearButton]="true"
                   width="100%"
                   (onValueChanged)="this.onQtyChanged($event)"
                   [inputAttr]="{style: 'text-align: center; font-size: 1.2em; font-weight: bold;'}"
    ></dx-number-box>
  </div>
  
  <div class="row punch-boxes">
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 1}"></ng-container>
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 5}"></ng-container>
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 10}"></ng-container>
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 20}"></ng-container>
  </div>
  
  <div class="row punch-boxes">
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 50}"></ng-container>
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 100}"></ng-container>
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 200}"></ng-container>
    <ng-container *ngTemplateOutlet="punchNumber; context: {number: 500}"></ng-container>
  </div>
  
  <div class="row punch-boxes">
    <div class="punch-box shortcut" title="Reverse Position" (click)="this.reversePosition()">
      <i class="fas fa-sync-alt"></i>
    </div>
    <div class="punch-box shortcut" title="Close Position" (click)="this.closePosition()">
      <i class="fas fa-times"></i>
    </div>
    <div class="punch-box shortcut" title="Double The Size" (click)="this.doublePosition()">
      <div class="math-op">
        <i class="fas fa-times fa-xs"></i>
        <span>2</span>
      </div>
    </div>
    <div class="punch-box shortcut" title="Reduce In Half" (click)="this.halfPosition()">
      <div class="math-op">
        <i class="fas fa-divide fa-xs"></i>
        <span>2</span>
      </div>
    </div>
  </div>
  
  <div class="row buttons">
    <div class="button apply">
      <dx-button text="Apply" type="default" width="100%"
      (onClick)="this.onApplyClicked()"
      ></dx-button>
    </div>
    <div class="button cancel">
      <dx-button text="Cancel" type="normal" width="100%"
      (onClick)="this.onCancelClicked()"
      ></dx-button>
    </div>
  </div>
</div>

<ng-template #cell let-value>
  <div class="cell" (click)="this.onQtySelected(value)">{{value}}</div>
</ng-template>

<ng-template #punchNumber let-num="number">
  <div class="punch-box">
    <div class="block sign minus" (click)="this.onMinusClicked(num)">-</div>
    <div class="block number">{{num}}</div>
    <div class="block sign plus" (click)="this.onPlusClicked(num)">+</div>
  </div>
</ng-template>