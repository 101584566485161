import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DetectMethodChanges, isValidNumber, isVoid} from "../../utils";
import {ToastrService} from "ngx-toastr";
import {HgTransactionPopupComponent} from "../transaction-popup/hg-transaction-popup.component";
import {HedgePositionTransaction} from "../data-model/hedge-position-transaction";
import {HedgePositionGroup} from "../data-model/hedge-position-group";
import {HedgeStateTransaction} from "../data-model/hedge-state-transaction";
import {filter, takeUntil} from "rxjs/operators";
import {MessageBusService} from "../../message-bus.service";
import {
    getGroupTotal,
    getOrderGroupTotal,
    getSubTotals
} from "../hg-totals-calculators";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {CashFlowStrategy} from "../../adjustment-control-panel/cash-flow-strategy";

@Component({
    selector: 'ets-hg-pricebox-section',
    templateUrl: 'hg-pricebox-section.component.html',
    styleUrls: [
        './hg-pricebox-section.component.scss',
        '../hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HgPriceboxSectionComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _messageBus: MessageBusService,
        private readonly _apgData: ApgDataService
    ) {
    }

    @ViewChild(HgTransactionPopupComponent) transactionPopupCmp: HgTransactionPopupComponent;

    private _stateTransactions: HedgeStateTransaction[];
    private _portfolioStrategy: CashFlowStrategy;

    get notEmpty(): boolean {
        return !isVoid(this.transactions);
    }

    selectedPortfolio: ApgPortfolio;

    transactions: HedgePositionTransaction[] = [];

    lastReviewedState: string;

    @Input() portfolioDefaultQty : number;

    ngOnInit() {
        this._messageBus.of('Hg.RefreshQuotes')
            .pipe(
                filter(x => this.transactions.length > 0)
            )
            .subscribe(x => this._changeDetector.detectChanges());

        this._messageBus.of('Hg.ZonesGridDataRequest')
            .subscribe(x => this.onZonesGridDataRequest(x.payload as any));

        this._messageBus.of('Hg.HedgesCleared')
            .subscribe(x => this.onHedgesCleared());
    }


    onZonesGridDataRequest(data: {hedges: HedgeStateTransaction[]}) {
        data.hedges = this._stateTransactions;
    }

    showTransactionPopup() {
        const isDouble = this._stateTransactions.length > 1 || this._portfolioStrategy === 'Calls & Puts';
        this.transactionPopupCmp
            .show(this.selectedPortfolio,
                this.portfolioDefaultQty,
                this._stateTransactions,
                isDouble
            );
    }

    getGroupTotal(group: HedgePositionGroup): number {

        const groupTotal = getGroupTotal(group, this.portfolioDefaultQty);

        return groupTotal;
    }

    getSubTotals(transType: 'current' | 'new'): number {

        const subTotal = getSubTotals(transType, this.transactions, this.portfolioDefaultQty);

        return subTotal;
    }

    getGroupTransTotal(row: HedgePositionTransaction): number {

        const transaction = this._stateTransactions
            ?.find(x => x.positionTransactions.indexOf(row) >= 0);

        if (isVoid(transaction)) {
            return undefined;
        }

        if (isVoid(transaction.orderTransactions)) {
            return undefined;
        }

        const label = row.afterState?.label || row.beforeState?.label;

        const oGroup = transaction.orderTransactions
            .find(x => x.label === label);

        const groupTransTotal = getOrderGroupTotal(oGroup, this.portfolioDefaultQty);

        return groupTransTotal;
    }

    getGrandTotal(): number {

        const total = this._stateTransactions
            ?.flatMap(x => x.orderTransactions)
            .map(x => getOrderGroupTotal(x, this.portfolioDefaultQty))
            .reduce((a,b) => (a || 0) + (b || 0), undefined);

        return total;

    }

    @DetectMethodChanges()
    setData(hedgeStateTransactions: HedgeStateTransaction[]) {

        this._stateTransactions = hedgeStateTransactions;

        const posTrans = hedgeStateTransactions
            .flatMap(x => x.positionTransactions);

        this.transactions = posTrans;
    }

    setReviewedState(state: string) {
        this.lastReviewedState = state;
    }

    getPriceClass(price: number) {
        if (!isValidNumber(price, true)) {
            return undefined;
        }
        return price > 0 ? 'credit' : 'debit';
    }

    private onHedgesCleared() {
        this.setData([]);
        this.setReviewedState(undefined);
    }

    async onPortfolioSelected(args: ApgPortfolio) {
        this.selectedPortfolio = args;
        this._apgData.getLastUsedTemplateOfPortfolio(args)
            .then(d => {
                this._portfolioStrategy = d?.strategy;
            })
            .catch(e => {});

        this.setReviewedState(undefined);
        this.setData([]);
    }
}